var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.logs.length)?_c('div',{staticClass:"history w-100"},[_vm._m(0),_vm._l((_vm.logs),function(log){return _c('div',{key:log.id,staticClass:"log d-flex flex-row"},[_c('div',{staticClass:"d-flex flex-column align-items-center timeline"},[_c('span',{staticClass:"icon-holder rounded",class:`bg-${_vm.actionColor(log.type)}`},[_c('i',{class:`icon-${_vm.actionIcon(log.type)}`})]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.$service.dateFormat( log.created_at, (_vm.currentFormat = "YYYY-MM-DD HH:mm"), (_vm.toFormat = "DD/MM/YYYY") ))+" ")]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.$service.dateFormat( log.created_at, (_vm.currentFormat = "YYYY-MM-DD HH:mm"), (_vm.toFormat = "HH[h]mm") ))+" ")]),_c('i',{staticClass:"icon-circle dot",class:`bg-${_vm.actionColor(log.type)} ${_vm.actionColor(log.type)}`}),_c('span',{staticStyle:{"border-right":"2px solid #c4c4c4","height":"100%","width":"1px"}})]),_c('div',{staticClass:"card-holder"},[(log.type === 'OpportunityClosedWon')?_c('opportunity-closed-won',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"step":log.data.sales_stage_name,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityClosedLost')?_c('opportunity-closed-lost',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"step":log.data.sales_stage_name,"data":log.data,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunitySalesStageUpdated')?_c('opportunity-sales-stage-updated',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"from":log.data.from_name,"to":log.data.to_name,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityPriceUpdated')?_c('opportunity-price-updated',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"from":log.data.from_price,"to":log.data.to_price,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityPriceCreated')?_c('opportunity-price-created',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"price":log.data.to_price,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityProbabilityUpdated')?_c('opportunity-probability-updated',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"from":log.data.from_probability,"to":log.data.to_probability,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityDescriptionUpdated')?_c('opportunity-description-updated',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"from":log.data.from_description,"to":log.data.to_description,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityProductCreated')?_c('opportunity-product-created',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"product":log.data.to_name,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityProductUpdated')?_c('opportunity-product-updated',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"from":log.data.from_name,"to":log.data.to_name,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityCreated')?_c('opportunity-created',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityUserUpdated')?_c('opportunity-user-updated',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"from":log.data.from_name,"to":log.data.to_name,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityReopened')?_c('opportunity-reopened',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityRecreated')?_c('opportunity-recreated',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityTma')?_c('opportunity-tma',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"tmaFormatted":log.data.tmaFormatted,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'TaskRegistry')?_c('task-registry',{attrs:{"data":log.data,"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"endType":log.data.end_type_name,"type":log.data.type_name,"description":log.data.description,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''},on:{"openTask":function($event){return _vm.openTask(log.data.id)}}}):_vm._e(),(log.type === 'TaskRegistryDeleted')?_c('task-registry-deleted',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"endType":log.data.end_type_name,"type":log.data.type_name,"description":log.data.description,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'TaskCreated')?_c('task-created',{attrs:{"data":log.data,"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"type":log.data.type_name,"description":log.data.description,"status":log.data.status_name,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''},on:{"openTask":function($event){return _vm.openTask(log.data.id)}}}):_vm._e(),(log.type === 'TaskClosed')?_c('task-closed',{attrs:{"data":log.data,"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"type":log.data.type_name,"description":log.data.description,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''},on:{"openTask":function($event){return _vm.openTask(log.data.id)}}}):_vm._e(),(log.type === 'TaskReopened')?_c('task-reopened',{attrs:{"data":log.data,"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"type":log.data.type_name,"description":log.data.description,"status":log.data.status_name,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''},on:{"openTask":function($event){return _vm.openTask(log.data.id)}}}):_vm._e(),(log.type === 'TaskDeleted')?_c('task-deleted',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"type":log.data.type_name,"description":log.data.description,"status":log.data.status_name,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityTransferred')?_c('opportunity-transferred',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"from":log.data.from_name,"to":log.data.to_name,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'ContractCreated')?_c('contract-created',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'ContractDeleted')?_c('contract-deleted',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'ContractPaymentStatusUpdated')?_c('contract-payment-status-updated',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'ContractStatusUpdated')?_c('contract-status-updated',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'ContractUpdated')?_c('contract-updated',{attrs:{"fromUserModal":_vm.fromUserModal,"title":_vm.getActionDescription(log.type),"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityRoomStatusClosed')?_c('opportunity-room-status-closed',{attrs:{"fromUserModal":_vm.fromUserModal,"roomId":log.data.remote_id,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityRoomStatusReopened')?_c('opportunity-room-status-reopened',{attrs:{"fromUserModal":_vm.fromUserModal,"roomId":log.data.remote_id,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityCallSuccess')?_c('opportunity-call-success',{attrs:{"id":log.data.id,"branch":log.data.voip_username,"attempts":log.data.attempt,"audioLink":log.data.recording_url,"fromUserModal":_vm.fromUserModal,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e(),(log.type === 'OpportunityCallAttempt')?_c('opportunity-call-attempt',{attrs:{"id":log.data.id,"branch":log.data.voip_username,"attempts":log.data.attempt,"fromUserModal":_vm.fromUserModal,"user":_vm.fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user,"opportunity":_vm.fromContactModal
              ? {
                  name: log.opportunity.name,
                  id: log.opportunity_id,
                }
              : ''}}):_vm._e()],1)])})],2):(_vm.hasRequest)?_c('div',{staticClass:"d-flex align-items-center justify-content-center mt-1"},[_c('b-spinner')],1):_vm._e(),(_vm.logs.length === 0)?_c('div',[_vm._m(1)]):_vm._e(),_c('b-modal',{staticClass:"modal-dialog",attrs:{"id":"bv-history-task","hide-footer":"","hide-header":""}},[_c('task-form',{attrs:{"task":_vm.currentTask,"isUpdate":true},on:{"closed":function($event){return _vm.refresh()},"saved":function($event){return _vm.refresh()},"deleted":function($event){return _vm.refresh()}}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"log header d-flex flex-row"},[_c('div',{staticClass:"d-flex flex-column align-items-center timeline"},[_vm._v("Data")]),_c('div',{staticClass:"card-holder"},[_vm._v("Histórico de atividades")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center justify-content-center mt-1"},[_c('span',{staticClass:"text-muted"},[_vm._v("Nenhum histórico encontrado")])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <!-- events -->
    <div class="history w-100" v-if="logs.length">
      <div class="log header d-flex flex-row">
        <div class="d-flex flex-column align-items-center timeline">Data</div>

        <div class="card-holder">Histórico de atividades</div>
      </div>

      <div v-for="log in logs" :key="log.id" class="log d-flex flex-row">
        <div class="d-flex flex-column align-items-center timeline">
          <span class="icon-holder rounded" :class="`bg-${actionColor(log.type)}`">
            <i :class="`icon-${actionIcon(log.type)}`" />
          </span>

          <span class="date"
            >{{
              $service.dateFormat(
                log.created_at,
                (currentFormat = "YYYY-MM-DD HH:mm"),
                (toFormat = "DD/MM/YYYY")
              )
            }}
          </span>

          <span class="time"
            >{{
              $service.dateFormat(
                log.created_at,
                (currentFormat = "YYYY-MM-DD HH:mm"),
                (toFormat = "HH[h]mm")
              )
            }}
          </span>

          <i
            class="icon-circle dot"
            :class="`bg-${actionColor(log.type)} ${actionColor(log.type)}`"
          />

          <span style="border-right: 2px solid #c4c4c4; height: 100%; width: 1px"></span>
        </div>

        <div class="card-holder">
          <opportunity-closed-won
            v-if="log.type === 'OpportunityClosedWon'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :step="log.data.sales_stage_name"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-closed-lost
            v-if="log.type === 'OpportunityClosedLost'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :step="log.data.sales_stage_name"
            :data="log.data"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-sales-stage-updated
            v-if="log.type === 'OpportunitySalesStageUpdated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :from="log.data.from_name"
            :to="log.data.to_name"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-price-updated
            v-if="log.type === 'OpportunityPriceUpdated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :from="log.data.from_price"
            :to="log.data.to_price"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-price-created
            v-if="log.type === 'OpportunityPriceCreated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :price="log.data.to_price"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-probability-updated
            v-if="log.type === 'OpportunityProbabilityUpdated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :from="log.data.from_probability"
            :to="log.data.to_probability"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-description-updated
            v-if="log.type === 'OpportunityDescriptionUpdated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :from="log.data.from_description"
            :to="log.data.to_description"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-product-created
            v-if="log.type === 'OpportunityProductCreated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :product="log.data.to_name"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-product-updated
            v-if="log.type === 'OpportunityProductUpdated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :from="log.data.from_name"
            :to="log.data.to_name"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-created
            v-if="log.type === 'OpportunityCreated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-user-updated
            v-if="log.type === 'OpportunityUserUpdated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :from="log.data.from_name"
            :to="log.data.to_name"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-reopened
            v-if="log.type === 'OpportunityReopened'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-recreated
            v-if="log.type === 'OpportunityRecreated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-tma
            v-if="log.type === 'OpportunityTma'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :tmaFormatted="log.data.tmaFormatted"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <task-registry
            v-if="log.type === 'TaskRegistry'"
            @openTask="openTask(log.data.id)"
            :data="log.data"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :endType="log.data.end_type_name"
            :type="log.data.type_name"
            :description="log.data.description"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <task-registry-deleted
            v-if="log.type === 'TaskRegistryDeleted'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :endType="log.data.end_type_name"
            :type="log.data.type_name"
            :description="log.data.description"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <task-created
            v-if="log.type === 'TaskCreated'"
            @openTask="openTask(log.data.id)"
            :data="log.data"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :type="log.data.type_name"
            :description="log.data.description"
            :status="log.data.status_name"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <task-closed
            v-if="log.type === 'TaskClosed'"
            @openTask="openTask(log.data.id)"
            :data="log.data"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :type="log.data.type_name"
            :description="log.data.description"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <task-reopened
            v-if="log.type === 'TaskReopened'"
            @openTask="openTask(log.data.id)"
            :data="log.data"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :type="log.data.type_name"
            :description="log.data.description"
            :status="log.data.status_name"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <task-deleted
            v-if="log.type === 'TaskDeleted'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :type="log.data.type_name"
            :description="log.data.description"
            :status="log.data.status_name"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-transferred
            v-if="log.type === 'OpportunityTransferred'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :from="log.data.from_name"
            :to="log.data.to_name"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <contract-created
            v-if="log.type === 'ContractCreated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <contract-deleted
            v-if="log.type === 'ContractDeleted'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <contract-payment-status-updated
            v-if="log.type === 'ContractPaymentStatusUpdated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <contract-status-updated
            v-if="log.type === 'ContractStatusUpdated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <contract-updated
            v-if="log.type === 'ContractUpdated'"
            :fromUserModal="fromUserModal"
            :title="getActionDescription(log.type)"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-room-status-closed
            v-if="log.type === 'OpportunityRoomStatusClosed'"
            :fromUserModal="fromUserModal"
            :roomId="log.data.remote_id"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-room-status-reopened
            v-if="log.type === 'OpportunityRoomStatusReopened'"
            :fromUserModal="fromUserModal"
            :roomId="log.data.remote_id"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-call-success
            v-if="log.type === 'OpportunityCallSuccess'"
            :id="log.data.id"
            :branch="log.data.voip_username"
            :attempts="log.data.attempt"
            :audioLink="log.data.recording_url"
            :fromUserModal="fromUserModal"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />

          <opportunity-call-attempt
            v-if="log.type === 'OpportunityCallAttempt'"
            :id="log.data.id"
            :branch="log.data.voip_username"
            :attempts="log.data.attempt"
            :fromUserModal="fromUserModal"
            :user="
              fromUserModal ? { name: log.opportunity.name, id: log.opportunity_id } : log.user
            "
            :opportunity="
              fromContactModal
                ? {
                    name: log.opportunity.name,
                    id: log.opportunity_id,
                  }
                : ''
            "
          />
        </div>
      </div>
    </div>

    <div v-else-if="hasRequest" class="d-flex align-items-center justify-content-center mt-1">
      <b-spinner />
    </div>

    <div v-if="logs.length === 0">
      <div class="d-flex align-items-center justify-content-center mt-1">
        <span class="text-muted">Nenhum histórico encontrado</span>
      </div>
    </div>

    <b-modal id="bv-history-task" class="modal-dialog" hide-footer hide-header>
      <task-form
        @closed="refresh()"
        @saved="refresh()"
        @deleted="refresh()"
        :task="currentTask"
        :isUpdate="true"
      />
    </b-modal>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import tmaServices from "../services/tmaServices";
import OpportunityClosedWon from "./components/History/OpportunityClosedWon.vue";
import OpportunityClosedLost from "./components/History/OpportunityClosedLost.vue";
import OpportunitySalesStageUpdated from "./components/History/OpportunitySalesStageUpdated.vue";
import OpportunityPriceCreated from "./components/History/OpportunityPriceCreated.vue";
import OpportunityPriceUpdated from "./components/History/OpportunityPriceUpdated.vue";
import OpportunityProbabilityUpdated from "./components/History/OpportunityProbabilityUpdated.vue";
import OpportunityDescriptionUpdated from "./components/History/OpportunityDescriptionUpdated.vue";
import OpportunityProductCreated from "./components/History/OpportunityProductCreated.vue";
import OpportunityProductUpdated from "./components/History/OpportunityProductUpdated.vue";
import OpportunityCreated from "./components/History/OpportunityCreated.vue";
import OpportunityUserUpdated from "./components/History/OpportunityUserUpdated.vue";
import OpportunityReopened from "./components/History/OpportunityReopened.vue";
import OpportunityRecreated from "./components/History/OpportunityRecreated.vue";
import OpportunityTma from "./components/History/OpportunityTma.vue";
import TaskRegistry from "./components/History/TaskRegistry.vue";
import TaskRegistryDeleted from "./components/History/TaskRegistryDeleted.vue";
import TaskCreated from "./components/History/TaskCreated.vue";
import TaskClosed from "./components/History/TaskClosed.vue";
import TaskReopened from "./components/History/TaskReopened.vue";
import TaskDeleted from "./components/History/TaskDeleted.vue";
import OpportunityTransferred from "./components/History/OpportunityTransferred.vue";
import ContractCreated from "./components/History/ContractCreated.vue";
import ContractDeleted from "./components/History/ContractDeleted.vue";
import ContractPaymentStatusUpdated from "./components/History/ContractPaymentStatusUpdated.vue";
import ContractStatusUpdated from "./components/History/ContractStatusUpdated.vue";
import ContractUpdated from "./components/History/ContractUpdated.vue";
import OpportunityRoomStatusClosed from "./components/History/OpportunityRoomStatusClosed.vue";
import OpportunityRoomStatusReopened from "./components/History/OpportunityRoomStatusReopened.vue";
import OpportunityCallSuccess from "./components/History/OpportunityCallSuccess.vue";
import OpportunityCallAttempt from "./components/History/OpportunityCallAttempt.vue";
import TaskForm from "@/views/forms/TaskForm.vue";

export default {
  components: {
    BSpinner,
    OpportunityClosedWon,
    OpportunityClosedLost,
    OpportunitySalesStageUpdated,
    OpportunityPriceCreated,
    OpportunityPriceUpdated,
    OpportunityProbabilityUpdated,
    OpportunityDescriptionUpdated,
    OpportunityProductCreated,
    OpportunityProductUpdated,
    OpportunityCreated,
    OpportunityUserUpdated,
    OpportunityReopened,
    OpportunityRecreated,
    OpportunityTma,
    TaskRegistry,
    TaskRegistryDeleted,
    TaskCreated,
    TaskClosed,
    TaskReopened,
    TaskDeleted,
    OpportunityTransferred,
    ContractCreated,
    ContractDeleted,
    ContractPaymentStatusUpdated,
    ContractStatusUpdated,
    ContractUpdated,
    OpportunityRoomStatusClosed,
    OpportunityRoomStatusReopened,
    OpportunityCallSuccess,
    OpportunityCallAttempt,
    TaskForm,
  },

  props: {
    contact: {
      default: null,
      required: true,
    },

    externalFetch: {
      type: Boolean,
      default: false,
      required: false,
    },

    fromUserModal: {
      type: Boolean,
      default: false,
      required: false,
    },

    fromContactModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  created() {
    if (this.externalFetch) {
      this.logs = this.contact;
      this.takeTmaLog(this.contact);
      this.hasRequest = false;
    } else {
      this.fetch();
    }
  },

  data() {
    return {
      logs: [],

      actions: [
        {
          id: "OpportunityClosedWon",
          title: "Oportunidade Ganha",
          icon: "thumbs-up",
          color: "blue",
        },
        {
          id: "OpportunityClosedLost",
          title: "Oportunidade Perdida",
          icon: "thumbs-down",
          color: "red",
        },
        {
          id: "OpportunitySalesStageUpdated",
          title: "Etapa do Funil Alterada",
          icon: "align",
          color: "purple",
        },
        {
          id: "OpportunityPriceUpdated",
          title: "Preço Alterado",
          icon: "charges",
          color: "light-blue",
        },
        {
          id: "OpportunityPriceCreated",
          title: "Preço Adicionado",
          icon: "charges",
          color: "blue",
        },
        {
          id: "OpportunityProbabilityUpdated",
          title: "Qualificação Alterada",
          icon: "star",
          color: "yellow",
        },
        {
          id: "OpportunityDescriptionUpdated",
          title: "Descrição Alterada",
          icon: "mail",
          color: "yellow",
        },
        {
          id: "OpportunityProductCreated",
          title: "Produto Adicionado",
          icon: "sale",
          color: "light-blue",
        },
        {
          id: "OpportunityProductUpdated",
          title: "Produto Atualizado",
          icon: "sale",
          color: "yellow",
        },
        {
          id: "OpportunityCreated",
          title: "Oportunidade Criada",
          icon: "check-circle-outline",
          color: "green",
        },
        {
          id: "OpportunityUserUpdated",
          title: "Transferência de Responsável",
          icon: "client",
          color: "purple",
        },
        {
          id: "OpportunityReopened",
          title: "Oportunidade Reaberta",
          icon: "transfer-error",
          color: "green",
        },
        {
          id: "OpportunityRecreated",
          title: "Oportunidade Recriada",
          icon: "transfer-error",
          color: "purple",
        },
        {
          id: "OpportunityTma",
          title: "Tempo de atendimento",
          icon: "task-clock",
          color: "red",
        },
        {
          id: "TaskRegistry",
          title: "Tarefa registrada",
          icon: "check",
          color: "green",
        },
        {
          id: "TaskRegistryDeleted",
          title: "Registro de Tarefa Deletado",
          icon: "close",
          color: "red",
        },
        {
          id: "TaskCreated",
          title: "Tarefa agendada",
          icon: "task-clock",
          color: "yellow",
        },
        {
          id: "TaskClosed",
          title: "Tarefa Finalizada",
          icon: "star",
          color: "yellow",
        },
        {
          id: "TaskReopened",
          title: "Tarefa Reaberta",
          icon: "refresh",
          color: "blue",
        },
        {
          id: "TaskDeleted",
          title: "Tarefa Deletada",
          icon: "close-circle",
          color: "red",
        },
        {
          id: "OpportunityTransferred",
          title: "Oportunidade transferida",
          icon: "check",
          color: "blue",
        },
        {
          id: "ContractCreated",
          title: "Contrato Criado",
          icon: "upload-file",
          color: "blue",
        },
        {
          id: "ContractDeleted",
          title: "Contrato Deletado",
          icon: "upload-file",
          color: "red",
        },
        {
          id: "ContractPaymentStatusUpdated",
          title: "Estatus de Pagamento Alterado",
          icon: "upload-file",
          color: "light-blue",
        },
        {
          id: "ContractStatusUpdated",
          title: "Estatus do Contrato Atualizado",
          icon: "pencil-line",
          color: "light-blue",
        },
        {
          id: "ContractUpdated",
          title: "Contrato Atualizado",
          icon: "pencil-line",
          color: "yellow",
        },
        {
          id: "OpportunityRoomStatusClosed",
          icon: "check",
          color: "blue",
        },
        {
          id: "OpportunityRoomStatusReopened",
          icon: "transfer-error",
          color: "green",
        },
        {
          id: "OpportunityCallSuccess",
          icon: "phone-chat-icon",
          color: "green",
        },
        {
          id: "OpportunityCallAttempt",
          icon: "phone-chat-icon",
          color: "red",
        },
      ],
      hasRequest: true,

      totalCount: 0,
      offset: 0,
      tma: {
        tmaValue: 0,
        tmaCreatedAt: 0,
        tmaCreatedAtHours: 0,
        tmaDate: 0,
        tmaDateHours: 0,
        steps: null,
      },

      hasTaskRequest: false,
      currentTask: {},
    };
  },

  methods: {
    refresh() {
      this.$emit("refresh");
    },

    openTask(id) {
      if (this.hasTaskRequest) {
        return;
      }

      this.hasTaskRequest = true;

      this.$store
        .dispatch("calendarStore/fetchOneTask", id)
        .then((response) => {
          this.currentTask = response.data;

          this.$store
            .dispatch("opportunityStore/fetchOne", this.currentTask.parent_id)
            .then((response) => {
              this.currentTask.opportunity = response.data;

              this.$bvModal.show("bv-history-task");
            })
            .finally(() => {
              this.hasTaskRequest = false;
            });
        })
        .catch(() => {
          this.hasTaskRequest = false;
        });
    },

    fetch() {
      let queryParams = [
        {
          query: "limit",
          param: 100,
        },
        {
          query: "offset",
          param: this.offset,
        },
      ];

      this.$store
        .dispatch("contactStore/fetchLogs", {
          contactId: this.contact.id,
          params: this.$service.formatQueryParam(queryParams),
        })
        .then((res) => {
          this.logs = res.data;
          this.takeTmaLog(this.logs);
          this.hasRequest = false;
        });
    },

    takeTmaLog(logs) {
      let created;
      for (let i = 0; i < logs.length; i++) {
        if (logs[i].type == "OpportunityTma") {
          this.tma.tmaValue = logs[i].data.tma;
          created = this.logs[i].data.opportunity_created_at;

          let tmaDate = new Date((this.tma.tmaValue + created) * 1000);
          this.tma.tmaDate = tmaDate.toLocaleDateString("PT-BR");
          this.tma.tmaDateHours = tmaDate.toLocaleTimeString("PT-BR");

          created = new Date(created * 1000);
          this.tma.tmaCreatedAt = created.toLocaleDateString("PT-BR");
          this.tma.tmaCreatedAtHours = created.toLocaleTimeString("PT-BR");
          this.tma.tmaValue = tmaServices.formatter(this.tma.tmaValue);

          logs[i].data.tmaFormatted = this.tma;

          this.tma = {};
        }
      }
    },

    actionIcon(action) {
      const current = this.actions.find((a) => a.id == action);

      if (!current) return;

      return current.icon;
    },

    actionColor(action) {
      const current = this.actions.find((a) => a.id == action);

      if (!current) return;

      return current.color;
    },

    getActionDescription(id) {
      const action = this.actions.find((action) => action.id === id);
      if (action) {
        return action.title;
      }
      return id;
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  .no-log {
    h2 {
      margin-top: 2.5em;
      margin-bottom: 1em;
    }
    .btn {
      text-transform: uppercase;
    }
  }

  .log {
    &.header {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #4c5862;
      padding: 13px 0px;
    }

    .icon-holder {
      width: 30px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }

    .timeline {
      flex-grow: 0;
      min-width: 80px;
      padding: 0px;

      .date {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;

        color: #212525;
      }
      .time {
        font-weight: 600;
        color: #999999;
      }

      .dot {
        border: 3px solid #c4c4c4;
        border-radius: 100%;
      }

      .bg-purple {
        background-color: #9160de;
      }
      .bg-red {
        background-color: #ff2c55;
      }
      .bg-light-blue {
        background-color: #38a7ff;
      }
      .bg-blue {
        background-color: #046af3;
      }
      .bg-green {
        background-color: #2ed47a;
      }
      .bg-yellow {
        background-color: #f8aa00;
      }
      .bg-orange {
        color: #f8aa00;
      }

      .purple {
        color: #9160de;
      }
      .red {
        color: #ff2c55;
      }
      .light-blue {
        color: #38a7ff;
      }
      .blue {
        color: #046af3;
      }
      .green {
        color: #2ed47a;
      }
      .yellow {
        color: #f8aa00;
      }
      .orange {
        color: #f8aa00;
      }
    }

    .card-holder {
      flex-grow: 1;

      .card {
        border-radius: 16px;
        border: 1px solid #d2d2d2;
      }
    }

    .icon-upload-file {
      font-size: 28px;
    }
  }
}
</style>

<template>
  <div :class="getCloseClass()" class="close-modal-general">
    <div @click="closeHalfModal" class="backdrop" />

    <div v-if="!showRoomCloseConfirmation" class="half-modal">
      <div class="absolute-check">
        <i class="icon-check" />
      </div>

      <div class="first-text">Status da Oportunidade</div>

      <div class="second-text">Atualize o status dessa oportunidade.</div>

      <div class="info-component">
        <avatar :avatarLetter="$service.getFirstLetter(opportunity.name)" />

        <div class="infos-second-column">
          <div style="font-size: 16px; font-weight: 500; color: #171717">
            {{ opportunity.name }}
          </div>

          <div style="color: #046af3">{{ "#" + opportunity.id }}</div>

          <div class="opportunity-bot-info">
            <div class="opportunity-bot-column" style="max-width: 50%">
              <div class="info-cell">
                <feather-icon icon="Share2Icon" size="12" style="margin-right: 5px" />

                {{ opportunity.media ? opportunity.media.name : "Sem mídia" }}
              </div>

              <div class="info-cell">
                <i class="icon-icon-notebook" style="margin-right: 5px" />

                {{ opportunity.workspace.name }}
              </div>
            </div>

            <div class="opportunity-bot-column">
              <div class="info-cell">
                <i
                  class="icon-imbot"
                  style="font-size: 16px; margin-left: -2px; margin-right: 3px"
                />

                {{ newestChat.bot_name }}
              </div>

              <div class="info-cell">
                <i class="icon-information-circle-outline" style="margin-right: 5px" />

                {{ "Sala: #" + newestChat?.id + " | " + "Status: " + getStateType(isOpenRoom) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isOpenRoom" class="close-buttons" style="font-size: 14px">
        <!-- <div
          @click="closeDeal('Lost')"
          :style="
            opportunity.state_type == 'Lost'
              ? 'background: #f7685b; color: white'
              : ''
          "
          class="close-button"
        >
          <i
            :style="
              opportunity.state_type == 'Lost'
                ? 'color: white'
                : 'color: #f7685b'
            "
            class="icon-thumbs-down"
            style="font-size: 20px"
          />

          Perdido
        </div>

        <div
          @click="closeDeal('Won')"
          :style="
            opportunity.state_type == 'Won'
              ? 'background: #2ed47a; color: white'
              : ''
          "
          class="close-button bordered-button"
        >
          <i
            :style="
              opportunity.state_type == 'Won'
                ? 'color: white'
                : 'color: #2ed47a'
            "
            class="icon-thumbs-up"
            style="font-size: 20px"
          />

          Ganho
        </div> -->

        <div @click="showConfirmation" class="close-button">
          <i class="icon-check-circle-outline" style="font-size: 20px; color: #046af3" />

          Fechar Sala
        </div>
      </div>

      <div v-else class="close-buttons" style="font-size: 14px">
        <div
          @click="closeDeal('Lost')"
          :style="opportunity.state_type == 'Lost' ? 'background: #f7685b; color: white' : ''"
          class="close-button"
        >
          <i
            :style="opportunity.state_type == 'Lost' ? 'color: white' : 'color: #f7685b'"
            class="icon-thumbs-down"
            style="font-size: 20px"
          />

          Perdido
        </div>

        <div
          @click="closeDeal('Won')"
          :style="opportunity.state_type == 'Won' ? 'background: #2ed47a; color: white' : ''"
          class="close-button bordered-button"
        >
          <i
            :style="opportunity.state_type == 'Won' ? 'color: white' : 'color: #2ed47a'"
            class="icon-thumbs-up"
            style="font-size: 20px"
          />

          Ganho
        </div>
      </div>
    </div>

    <div v-else class="half-modal-confirmation">
      <div class="absolute-check">
        <i class="icon-check" />
      </div>

      <div style="padding: 5px 20px">
        <div class="warning-box">
          <div style="font-size: 20px; font-weight: 500; color: black">Aviso!</div>

          <div>
            Ao encerrar a conversa, a troca de mensagens entre você e a oportunidade não será mais
            possível até que você reabra esta sala.
          </div>

          <b style="color: black">Tem certeza que deseja fechar esta sala? </b>
        </div>

        <div :class="getConfirmationButtonClass()">
          <b-button @click="closeConfirmation" variant="light"> Cancelar </b-button>

          <b-button @click="closeChat" variant="primary">
            <div v-if="!hasCloseRoomRequest">Confirmar</div>

            <b-spinner v-else />
          </b-button>
        </div>
      </div>
    </div>

    <ClosingOpportunityModals
      @saveDeal="saveDeal($event)"
      @refresh="$emit('refresh')"
      :opportunityProp="opportunity"
    />

    <b-modal
      class="modal-dialog"
      hide-footer
      hide-header
      id="bv-data_correction"
      no-close-on-backdrop
    >
      <data-correction
        :opportunityId="opportunity.id"
        :isFrom="isTypeWon ? 'Won' : ''"
        @saved="saveDataCorrection($event)"
      />
    </b-modal>

    <b-modal id="bv-enrollment_form" class="modal-dialog" hide-footer hide-header>
      <enrollment-form @closed="hideModal('bv-enrollment_form')" />
    </b-modal>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";
import { BButton, BContainer, BFormGroup, BForm, BFormTextarea, BSpinner } from "bootstrap-vue";
import ButtonComponent from "@/views/components/ButtonComponent.vue";
import ClosingOpportunityModals from "@/views/components/ClosingOpportunityModals.vue";
import DataCorrection from "@/views/components/DataCorrection.vue";
import { verificationsForStepChange } from "@/mixins";
import vSelect from "vue-select";

export default {
  mixins: [verificationsForStepChange],

  components: {
    Avatar,
    BButton,
    BContainer,
    BFormGroup,
    BForm,
    BFormTextarea,
    BSpinner,
    ButtonComponent,
    ClosingOpportunityModals,
    DataCorrection,
    vSelect,
  },

  props: {
    opportunity: {
      type: Object,
      required: true,
      default: null,
    },

    fromOpportunityDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },

    fromOmnichannelDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },

    newestChat: {
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      hasRequest: true,
      hasCloseRoomRequest: false,
      isTypeWon: false,
      showRoomCloseConfirmation: false,
    };
  },

  computed: {
    platform_terms() {
      const platformData = this.$store.getters["whiteLabel/getPlatform"];

      return platformData.terms;
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },

    reasons_lost_filtered() {
      const deepClone = require("lodash");

      let options = deepClone.cloneDeep(this.$store.getters["opportunityStore/getLostReasons"]);

      let lostReasons = Object.values(options);

      return lostReasons.filter((option) => option.id != "other");
    },

    isOpenRoom() {
      if (!this.newestChat) {
        return false;
      }

      return this.newestChat.closed_at === undefined || this.newestChat.closed_at === null;
    },
  },

  created() {
    this.$store.dispatch("whiteLabel/setPlatform");

    this.fetchTotalStages();
  },

  methods: {
    getCloseClass() {
      if (!this.fromOmnichannelDesktop && !this.fromOpportunityDesktop) {
        return "close-modal-general-mobile";
      }

      if (this.fromOmnichannelDesktop) {
        return "close-modal-general-omnichannel-desktop";
      }

      if (this.fromOpportunityDesktop) {
        return "close-modal-general-desktop";
      }
    },

    getConfirmationButtonClass() {
      if (!this.fromOmnichannelDesktop && !this.fromOpportunityDesktop) {
        return "confirmation-buttons";
      }

      if (this.fromOmnichannelDesktop) {
        return "confirmation-buttons-omnichannel-desktop";
      }

      if (this.fromOpportunityDesktop) {
        return "confirmation-buttons-desktop";
      }
    },

    setIsClosed(status) {
      return this.opportunity.state_type.toLowerCase() === status;
    },

    openModal(modal) {
      this.$bvModal.show(`${modal}`);
    },

    hideModal(modal) {
      this.$bvModal.hide(`${modal}`);
    },

    closeHalfModal() {
      this.$emit("closeHalfModal");
    },

    showConfirmation() {
      this.showRoomCloseConfirmation = true;
    },

    closeConfirmation() {
      this.showRoomCloseConfirmation = false;
    },

    getStateType(status) {
      if (status) {
        return "Aberta";
      }

      return "Fechada";
    },

    saveDataCorrection(eventInfo) {
      if (eventInfo.product_id || eventInfo.price) {
        this.opportunity.product_id = eventInfo.product_id;
        this.opportunity.price = eventInfo.price;
      }

      this.MIXIN_saveDataCorrection();
    },

    saveDeal(event) {
      if (event.opportunity) {
        this.getObjectAttributes(event.opportunity, this.opportunity);
      }

      this.hideModal(`bv-close_deal_${event.state.toLowerCase()}`);

      this.MIXIN_saveDeal(event.state);
    },

    getObjectAttributes(source, modifiedObject) {
      for (let attribute in source) {
        if (source.hasOwnProperty(attribute)) {
          modifiedObject[attribute] = source[attribute];
        }
      }
    },

    closeDeal(state) {
      this.isTypeWon = false;

      let stateType = this.opportunity.state_type;

      if (state == "Won") {
        this.isTypeWon = true;
      } else {
        this.isTypeWon = false;
      }

      if (stateType == state) {
        this.openModal("bv-update_kanban_stage");
      } else {
        this.chengeSaleStage(state);
        this.MIXIN_verificationsForStepChange();
      }
    },

    chengeSaleStage(stateType) {
      if (stateType.toLowerCase() == "won") {
        this.opportunity.sales_stage_id = this.getIdOfStep("Won");
      }

      if (stateType.toLowerCase() == "lost") {
        this.opportunity.sales_stage_id = this.getIdOfStep("Lost");
      }

      if (stateType.toLowerCase() == "open") {
        this.opportunity.sales_stage_id = this.getIdOfStep("Default");
      }

      this.hideModal(`bv-close_deal_${stateType.toLowerCase()}`);
    },

    getIdOfStep(stepType) {
      let stepId = null;

      this.totalStages.forEach((element) => {
        if (element.type == stepType) {
          stepId = element.id;

          return;
        }
      });

      return stepId;
    },

    fetchTotalStages() {
      this.$store
        .dispatch("opportunityStore/fetchStages")
        .then((response) => {
          this.totalStages = response.data;
        })
        .finally(() => {
          this.hasRequest = false;
        });
    },

    closeChat() {
      if (this.hasCloseRoomRequest) {
        return;
      }

      this.hasCloseRoomRequest = true;

      this.$store
        .dispatch("omnichannel/closeChat", this.newestChat?.id)
        .then(() => {
          this.$bvToast.toast("Sala do IMBOT fechada com sucesso!", {
            title: `Sala fechada!`,
            autoHideDelay: 1500,
            variant: "success",
            toaster: "b-toaster-top-left",
            solid: true,
          });

          setTimeout(() => {
            this.$emit("refresh");
            this.closeHalfModal();
          }, 1500);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.hasCloseRoomRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.close-modal-general-mobile {
  position: absolute;
}

.close-modal-general-omnichannel-desktop {
  position: fixed;
  max-width: calc(33vw - 30px);
  bottom: 0;
}

.close-modal-general-desktop {
  position: fixed;
  max-width: 500px;
  bottom: 0;
}

.close-modal-general {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  z-index: 10000;
  background: #00000027;
  overflow: hidden;

  .backdrop {
    height: 100%;
    width: 100%;
  }

  .half-modal-confirmation {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 285px;
    min-height: 285px;
    padding: 45px 0 64px 0;
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    background: white;

    .absolute-check {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      font-size: 22px;
      height: 78px;
      width: 78px;
      top: -39px;
      left: calc(50% - 39px);
      border-radius: 22px;
      background: #c4e2ff;
      color: #046af3;
    }

    .warning-box {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
      padding: 15px;
      gap: 10px;
      border-radius: 6px;
      background: #f5f6f8;
    }
  }

  .half-modal {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 275px;
    min-height: 275px;
    padding: 45px 15px 10px 15px;
    gap: 10px;
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    background: white;

    .absolute-check {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      font-size: 22px;
      height: 78px;
      width: 78px;
      top: -39px;
      left: calc(50% - 39px);
      border-radius: 22px;
      background: #c4e2ff;
      color: #046af3;
    }

    .first-text {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.20000000298023224px;
      text-align: center;
      color: #000000;
    }

    .second-text {
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.20000000298023224px;
      text-align: center;
      color: #4c5862;
    }

    .info-component {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      width: 100%;
      padding: 7px 10px;
      border-radius: 8px;
      background: #f5f6f8;
      overflow: hidden;

      .infos-second-column {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;

        .opportunity-bot-info {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 5px;

          .opportunity-bot-column {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: fit-content;
            overflow: hidden;

            .info-cell {
              display: flex;
              align-items: center;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 100%;
              overflow: hidden;
            }
          }
        }
      }
    }

    .close-button:hover {
      background: #046af3;
      color: #ffffff !important;

      i {
        color: #ffffff !important;
      }
    }

    .close-buttons {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 4px;
      border: solid 1px #c4ccd8;

      .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        width: 100%;
        padding: 15px 5px;
        color: #333333;
        cursor: pointer;

        i {
          margin-right: 5px;
        }
      }

      .bordered-button {
        border-left: solid 1px #c4ccd8;
        border-right: solid 1px #c4ccd8;
      }
    }
  }

  .confirmation-buttons,
  .confirmation-buttons-omnichannel-desktop,
  .confirmation-buttons-desktop {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    height: 64px;
    width: 100%;
    bottom: 0;
    border-top: solid 1px #f5f6f8;
  }

  .confirmation-buttons {
    left: 0;
    max-width: 500px;

    @media (max-width: 800px) {
      max-width: none;
    }
  }

  .confirmation-buttons-omnichannel-desktop {
    max-width: calc(33vw - 30px);
    right: 0;
  }

  .confirmation-buttons-desktop {
    max-width: 500px;
    left: 0;
  }
}
</style>

<template>
  <div class="notification-overscreen">
    <div
      v-if="isShowingNotification && localNotification"
      @click="openNotificationSource()"
      class="notification-toast animate__animated animate__fadeIn"
    >
      <div class="icon-holder">
        <feather-icon icon="MessageSquareIcon" size="18" color="#1dc068" />
      </div>

      <div class="d-flex" style="flex-direction: column">
        <div style="font-size: 15px; font-weight: 500; color: #000000">
          {{ localNotification.data.title }}
        </div>

        <div style="font-size: 16px; color: #6e6b7b">{{ localNotification.data.body }}</div>
      </div>
    </div>

    <b-modal
      id="bv-opportunity-from-notification-toast"
      class="modal-dialog"
      hide-footer
      hide-header
    >
      <opportunity-and-contacts
        v-if="currentOpportunity"
        @saved="$bvModal.hide('bv-opportunity-from-notification-toast')"
        @closed="$bvModal.hide('bv-opportunity-from-notification-toast')"
        @refresh="$bvModal.hide('bv-opportunity-from-notification-toast')"
        :opportunity="currentOpportunity"
        :fromOmnichannelMobile="isFromOmnichannel"
      />
    </b-modal>

    <b-modal id="bv-task-from-notification-toast" class="modal-dialog" hide-footer hide-header>
      <task-form
        @closed="$bvModal.hide('bv-task-from-notification-toast')"
        @saved="$bvModal.hide('bv-task-from-notification-toast')"
        @deleted="$bvModal.hide('bv-task-from-notification-toast')"
        :task="currentTask"
        :isUpdate="true"
      />
    </b-modal>
  </div>
</template>

<script>
import { errorHandling } from "@/mixins";
import OpportunityAndContacts from "@/views/forms/OpportunityAndContacts.vue";
import TaskForm from "@/views/forms/TaskForm.vue";
import notificationAudio from "@/assets/audio/omni-notification.mp3";

export default {
  mixins: [errorHandling],

  components: {
    OpportunityAndContacts,
    TaskForm,
  },

  data() {
    return {
      localNotification: null,
      localNotificationSettings: null,

      hasRedirectionRequest: false,

      currentOpportunity: null,
      isFromOmnichannel: false,

      currentTask: null,

      notificationRingTone: null,
    };
  },

  computed: {
    notification() {
      return this.$store.getters["firebase/getCurrentNotification"];
    },

    isShowingNotification() {
      return this.$store.getters["firebase/getIsShowingNotification"];
    },

    had_user_interaction_tick() {
      return this.$store.getters["externalPhone/getUserInteractionTick"];
    },

    isIos() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
  },

  watch: {
    notification: {
      handler() {
        this.handleNotification();
      },
      deep: true,
    },

    had_user_interaction_tick() {
      this.prepareAudios();
    },
  },

  methods: {
    setStoreIsShowingNotification(bool) {
      this.$store.commit("firebase/setIsShowingNotification", bool);
    },

    playRingTone() {
      if (this.isIos || !this.notificationRingTone) {
        return;
      }

      this.notificationRingTone.currentTime = 0;
      this.notificationRingTone
        .play()
        .catch((error) => console.log("Error playing notificationRingTone:", error));

      setTimeout(() => {
        this.notificationRingTone.pause();
      }, 1500);
    },

    handleNotification() {
      if (this.notification == null) return;

      if (Object.keys(this.notification).length !== 0) {
        this.localNotification = this.notification;

        const unparsedSettings = this.notification.data?.user_settings;

        if (unparsedSettings) {
          this.localNotificationSettings = JSON.parse(unparsedSettings);

          this.handleUnreadNotification();

          this.handleShowNotification();

          this.handleDownloadExport();
        }
      }
    },

    handleUnreadNotification() {
      const data = this.localNotification?.data;

      if (data.origin === "Dialog" && data.id) {
        this.$store.commit("firebase/addUnreadOmniNotifications", Number(data.id));
      }
    },

    handleShowNotification() {
      let data = this.localNotification.data;

      let canShow = false;
      let isOmni = false;
      let keyType = "";

      const possibleOrigins = {
        Opportunity: () => {
          keyType = "enable_new_opportunity_notification";
        },

        Task: () => {
          keyType = "enable_new_task_notification";
        },

        Dialog: () => {
          isOmni = true;

          keyType = "enable_new_omnichannel_notification";
        },
      };

      if (possibleOrigins[data.origin]) {
        possibleOrigins[data.origin]();
      }

      this.localNotificationSettings.forEach((element) => {
        if (element.key === keyType && element.value) {
          canShow = true;

          return;
        }
      });

      if (isOmni && window.location.pathname.includes("/omnichannel")) {
        this.$store.commit("firebase/clearCurrentNotification");

        return;
      }

      if (canShow) {
        if (isOmni) this.playRingTone();

        this.setStoreIsShowingNotification(true);

        setTimeout(() => {
          this.$store.commit("firebase/clearCurrentNotification");

          this.setStoreIsShowingNotification(false);
        }, 5000);
      }
    },

    handleDownloadExport() {
      const data = this.localNotification?.data;

      const isExport = data.origin === "ExportContactBatchJob" || data.origin === "ExportPdcaJob";

      if (isExport && data.id) {
        const fileName = data.filename;

        this.$store
          .dispatch("exports/generateDownloadLink", data.id)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response]));

            const link = document.createElement("a");

            link.href = url;

            link.setAttribute("download", fileName);

            document.body.appendChild(link);

            link.click();
          })
          .catch((error) => {
            this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
          });
      }
    },

    openNotificationSource() {
      if (!this.localNotification) return;

      const data = this.localNotification.data;

      const possibleOrigins = {
        Dialog: () => {
          this.isFromOmnichannel = true;

          this.requestOpportunityAndOpenModal(data.opportunity_id);
        },

        Opportunity: () => {
          this.isFromOmnichannel = false;

          this.requestOpportunityAndOpenModal(data.id);
        },

        Task: () => {
          this.requestTaskAndOpenModal(data.id);
        },

        LeadImportJob: () => {
          return;
        },
      };

      if (possibleOrigins[data.origin]) {
        possibleOrigins[data.origin]();

        return;
      }

      this.$bvToast.toast("Não é possível ser direcionado para a origem dessa notificação.", {
        title: "Notificação não direcionável!",
        autoHideDelay: 2000,
        variant: "warning",
        toaster: "b-toaster-top-left",
        solid: true,
      });
    },

    requestOpportunityAndOpenModal(opportunityId) {
      if (this.hasRedirectionRequest) return;

      this.hasRedirectionRequest = true;

      this.$store
        .dispatch("opportunityStore/fetchOne", opportunityId)
        .then((response) => {
          this.currentOpportunity = response.data;

          this.$bvModal.show("bv-opportunity-from-notification-toast");
        })
        .catch((error) => {
          this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
        })
        .finally(() => {
          this.hasRedirectionRequest = false;
        });
    },

    requestTaskAndOpenModal(taskId) {
      if (this.hasRedirectionRequest) return;

      this.hasRedirectionRequest = true;

      this.$store
        .dispatch("calendarStore/fetchOneTask", taskId)
        .then((response) => {
          this.currentTask = response.data;

          this.$bvModal.show("bv-task-from-notification-toast");
        })
        .catch((error) => {
          this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
        })
        .finally(() => {
          this.hasRedirectionRequest = false;
        });
    },

    async prepareAudios() {
      if (this.isIos) return;

      this.notificationRingTone = new Audio(notificationAudio);

      document.addEventListener(
        "touchstart",
        async () => {
          this.notificationRingTone.muted = true;

          this.notificationRingTone
            .play()
            .then((_) => {
              this.notificationRingTone.pause();
              this.notificationRingTone.muted = false;
            })
            .catch((error) => console.log("Error playing notificationRingTone:", error));
        },
        { once: true }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-overscreen {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  .notification-toast {
    display: flex;
    align-items: center;
    position: fixed;
    top: 1rem;
    left: 50%;
    gap: 10px;
    transform: translateX(-50%);
    z-index: 9999999;
    padding: 1rem;
    border-radius: 6px;
    border: solid 1px #1dc068;
    border-left: solid 3px #1dc068;
    background: #e5f5ed;
    pointer-events: all;
    cursor: pointer;

    .icon-holder {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      width: 34px;
      border-radius: 100%;
      background: #c4e3d3;
    }
  }
}
</style>

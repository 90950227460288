<template>
  <b-card id="taskCreated">
    <div class="header">
      <span class="title">VOIP #{{ id }}</span>

      <span class="title branch-token">Ramal {{ branch }}</span>
    </div>

    <div class="d-flex body">
      <div style="display: flex; flex-direction: column; width: 100%; margin: 4px 0">
        <span style="padding: 5px 10px 0px 10px; color: #999999">
          Tentativas até atender: {{ attempts }}
        </span>

        <audio class="audio-box" controls :src="audioLink">
          <a :href="audioLink"> Download audio </a>
        </audio>
      </div>
    </div>

    <div class="footer d-flex flex-column">
      <div
        class="info"
        v-if="user"
        @click="fromUserModal ? openOpportunity(user.id) : ''"
        :class="fromUserModal ? 'cursor-pointer' : ''"
      >
        {{ fromUserModal ? "Realizado na oportunidade:" : "Realizado por:" }}

        <div class="d-flex flex-row">
          <Avatar
            :hasStatus="false"
            :avatarLetter="$service.getFirstLetter(user.name || 'X')"
            avatarColor="#FFFFFF"
            avatarBackground="#109CF1"
          />

          <div class="d-flex flex-column">
            <span class="name"> {{ user.name }} </span>
            <span class="cod" v-if="user.id"> #{{ user.id }} </span>
          </div>
        </div>
      </div>

      <div
        class="info cursor-pointer"
        style="margin-top: 10px"
        v-if="opportunity"
        @click="openOpportunity(opportunity.id)"
      >
        {{ "Realizado na oportunidade:" }}

        <div class="d-flex flex-row">
          <Avatar
            :hasStatus="false"
            :avatarLetter="$service.getFirstLetter(opportunity.name || 'X')"
            avatarColor="#FFFFFF"
            avatarBackground="#109CF1"
          />

          <div class="d-flex flex-column">
            <span class="name"> {{ opportunity.name }} </span>

            <span class="cod" v-if="opportunity.id"> #{{ opportunity.id }} </span>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import Avatar from "../Avatar.vue";

export default {
  name: "TaskCreated",

  components: {
    BCard,
    Avatar,
  },

  props: {
    id: {
      type: Number,
    },

    branch: {},

    attempts: {
      type: Number,
    },

    audioLink: {
      type: String,
    },

    user: {
      type: Object,
    },

    opportunity: {
      default: null,
    },

    fromUserModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  methods: {
    openOpportunity(id) {
      this.$store.dispatch("userStore/setOpportunityForHistory", id).then(() => {
        this.$bvModal.show("bv-user-update_opportunity_modal");
      });
    },
  },
};
</script>

<style lang="scss">
.card {
  box-shadow: none;
  border: 1px solid #c4ccd8;
  border-radius: 10px;
  margin: 0 1em 1.5em;
  text-align: left;

  & > .card-body {
    padding: 0rem;

    .header {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
      border-bottom: 1px solid #c4ccd8;

      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #4c5862;
      }

      .branch-token {
        padding: 5px 10px;
        border-radius: 10px 10px 0px 10px;
        background: #e5e5e5;
      }
    }

    .footer {
      border-top: 1px solid #c4ccd8;
      padding: 0.5rem;
      flex-flow: row;
      display: flex;
      justify-content: space-between;

      .user {
        flex-flow: row;
        display: flex;

        .info {
          flex-flow: column;
          display: flex;

          .cod {
            color: #afafaf;
            font-size: 0.85em;
          }
        }
      }
    }

    .body {
      padding: 0 0.5em;

      .audio-box {
        width: 100%;
        margin: 5px 0;
      }

      audio::-webkit-media-controls-panel {
        border-radius: 1px !important;
        background-color: #e5e5e5;
      }

      h3 {
        font-weight: 500;
        font-size: 1em;
        line-height: 24px;
        color: #046af3;
        margin: 0px;
      }

      .name {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        color: #000000;
        margin: 0px;
      }

      .info-container {
        display: flex;
        flex-flow: row;
      }
    }
  }
}
</style>

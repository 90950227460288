<template>
  <b-container @keydown="handleKeydown" class="general-container">
    <close-opportunity-from-chat
      v-if="showCloseChat"
      @closeHalfModal="closeHalfModal"
      @refresh="emitEvents('saved')"
      :fromOpportunityDesktop="true"
      :fromOmnichannelDesktop="isFromOmnichannelDesktop"
      :opportunity="opportunity"
      :newestChat="newestChat"
    />

    <div class="top-menu" v-if="!isUpdate">
      <div class="action-buttons">
        <i class="icon-close" @click="emitEvents('closed')" />
      </div>
    </div>

    <line-component :color="lineColor" v-if="!isFromOmnichannelDesktop" />

    <validation-observer ref="formValidation">
      <b-form
        :class="isFromOmnichannelDesktop ? 'margin-form-omnichannel' : 'margin-form'"
        :style="isUpdate ? '' : 'margin-top: 40px'"
        @submit.prevent
      >
        <div
          class="middle-container"
          :class="{
            noPaddingOmnichannel: isFromOmnichannelDesktop,
            'mb-0': isFromExternalCall,
          }"
          v-if="isUpdate"
        >
          <div class="mb-1" v-if="isUpdate && !hasRequest && currentWorkspace">
            <card-top-info-component
              @whats="taskFromWhats"
              @call="taskFromCall"
              @singleVoipCall="singleVoipCall"
              @email="taskFromEmail"
              @closeByChat="closeByChat"
              @editOpportunityName="changeOpName($event)"
              :opportunity="opportunity"
              :isFromExternalCall="isFromExternalCall"
              :fromOmnichannelDesktop="isFromOmnichannelDesktop"
              :newestChat="newestChat"
              :editable="true"
            />
          </div>

          <div
            class="tma-dates"
            :class="{ tmaDatesWarning: showWarningTma && !opportunity.tma }"
            v-if="isUpdate"
          >
            <span id="createdDate"
              >Criado em:
              <b
                >{{
                  this.$service.dateFormat(
                    this.opportunity.created_at ? this.opportunity.created_at : createdAtBuffer,
                    "YYYY-MM-DD HH:mm:ss",
                    "DD/MM/YY"
                  )
                }}
                às
                {{
                  this.$service.dateFormat(
                    this.opportunity.created_at ? this.opportunity.created_at : createdAtBuffer,
                    "YYYY-MM-DD HH:mm:ss",
                    "HH:mm"
                  )
                }}</b
              ></span
            >
            <div id="updatedDate">
              <img
                class="thermomether"
                :src="`${require('@/assets/images/icons/thermometer-red.svg')}`"
                v-if="showAlertTma"
              />

              <img
                class="checkGroup"
                :src="`${require('@/assets/images/icons/check-group.svg')}`"
                v-else-if="showDefaultTma"
              />

              <img
                class="checkGroup"
                :src="`${require('@/assets/images/icons/check-group-red.svg')}`"
                v-else-if="showWarningTma"
              />

              <div>
                <span
                  v-if="!opportunity.tma"
                  :class="{
                    showAlertTma: showAlertTma,
                    hideAlertTma: !showAlertTma,
                  }"
                  >{{ tma }} sem interação</span
                >

                <span v-else-if="opportunity.tma && opportunity.tma < 600" class="hideAlertTma">
                  Atendido em {{ tma }}
                </span>

                <span v-else-if="opportunity.tma && opportunity.tma > 600" class="showAlertTma"
                  >Atendido em {{ tma }}
                </span>
              </div>
            </div>
          </div>

          <div class="rating" label-for="rating">
            <div>
              <feather-icon icon="AlertCircleIcon" size="24" @click="openModal('bv-help_modal')" />

              <span style="font-size: 18px; margin-left: 10px">Qualificação</span>
            </div>

            <RateBar
              v-if="!hasRequest"
              :is-edit="true"
              :rate="opportunity.probability"
              :size="2"
              @update-rating="updateRating"
            />
          </div>

          <div class="state-type-action" style="font-size: 14px" v-if="isUpdate">
            <button-component
              class="button-component"
              icon-left="icon-thumbs-down"
              style-type="outline"
              v-ripple="'rgba(255, 55, 55, 0.2)'"
              style="padding: 13px"
              :text="platform_terms.lost_term"
              :class="setIsClosed('lost') ? 'selected' : ''"
              @click.native="closeDeal('Lost')"
            />

            <button-component
              class="button-component"
              icon-left="icon-thumbs-up"
              style-type="outline"
              v-ripple="'rgba(255, 255, 255, 0.2)'"
              style="padding: 13px"
              :text="platform_terms.won_term"
              :class="setIsClosed('won') ? 'selected' : ''"
              @click.native="closeDeal('Won')"
            />
          </div>

          <div
            v-if="
              setIsClosed('won') &&
              platformData.integrated_enroll == 'imNic' &&
              checkContractStatus() &&
              checkImNicToken()
            "
            @click="openEnroll"
            class="enrollment-button"
          >
            <i
              class="icon-upload-file"
              style="color: #046af3; font-size: 30px; margin-right: 10px"
            />
            Gerar Matrícula
          </div>

          <contract-status
            v-if="opportunity.contract"
            :contract="opportunity.contract"
            :dependent="{
              isResponsable: opportunity.is_responsible,
              studentName: opportunity.student_name,
              studentDocument: opportunity.student_document,
            }"
          />

          <div
            class="state-type-action-message"
            v-if="opportunity.state_type.toLowerCase() == 'lost'"
          >
            <h4>{{ getFormatedClosedType(opportunity.closed_type) }}.</h4>

            <span>
              {{
                opportunity.closed_description ? opportunity.closed_description : "Sem descrição"
              }}
            </span>
          </div>

          <span
            class="step"
            v-if="isUpdate"
            :class="`bg border`"
            :style="{
              background: checkIfhasHash(currentStep.color),
              border: '2px solid' + currentStep.borderColor + '!important',
            }"
            @click="triggerBoardChange"
          >
            Etapa : {{ currentStep.title }}
          </span>

          <b-button
            variant="primary"
            class="mb-1 add-task-button"
            v-if="isUpdate"
            @click="showTaskOptionsCollapse = !showTaskOptionsCollapse"
          >
            <feather-icon :icon="showTaskOptionsCollapse ? 'MinusCircleIcon' : 'PlusCircleIcon'" />
            Adicionar Tarefa
          </b-button>

          <b-collapse id="collapse-1" class="mb-1" v-model="showTaskOptionsCollapse">
            <div class="task-collapse animate__animated animate__bounceIn">
              <span class="task-collapse-title">Deseja registrar ou agendar uma tarefa? </span>

              <div @click="openTaskModal(true)" class="task-buttons task-register">
                <span>Registrar Tarefa</span>

                <i class="icon-keyboard-arrow-right ml-1" style="font-size: 10px" />
              </div>

              <div @click="openTaskModal(false)" class="task-buttons task-schedule">
                <span> Agendar Tarefa </span>

                <i class="icon-keyboard-arrow-right ml-1" style="font-size: 10px" />
              </div>
            </div>
          </b-collapse>
        </div>

        <div
          v-if="isFromExternalCall && external_phone_status != 'paused'"
          class="call-component-holder"
        >
          <div class="call-ongoing">
            Em atendimento

            <div class="white-green-circle" />
          </div>

          <b-button @click="terminateCall" class="dial-stop" variant="danger">
            <feather-icon icon="PhoneOffIcon" size="18" />

            Finalizar
          </b-button>
        </div>

        <line-component :color="lineColor" v-if="isUpdate" />

        <div class="d-flex flex-column p-2">
          <b-tabs :id="!isUpdate ? 'icons-menu' : ''" content-class="mt-3" fill>
            <b-tab title="Dados" :active="showFirst">
              <div class="rating" label-for="rating" v-if="!isUpdate">
                <div>
                  <feather-icon
                    icon="AlertCircleIcon"
                    size="24"
                    @click="openModal('bv-help_modal')"
                  />

                  <span style="font-size: 18px; margin-left: 10px"> Qualificação </span>
                </div>

                <RateBar
                  v-if="!hasRequest"
                  :is-edit="true"
                  :rate="opportunity.probability"
                  :size="2"
                  @update-rating="updateRating"
                />
              </div>

              <span
                class="step"
                v-if="!isUpdate"
                :style="{
                  background: '#' + currentStep.color,
                  border: '2px solid' + currentStep.borderColor + '!important',
                }"
                @click="triggerBoardChange"
              >
                Etapa : {{ currentStep.title }}
              </span>

              <line-component :color="lineColor" style="margin: 15px 0" />

              <legend>Informações</legend>

              <input-field-component
                v-if="!isUpdate"
                id="opportunity_name"
                placeholder="Nome da Oportunidade"
                trim
                type="text"
                v-model="opportunity.name"
              />

              <validation-provider
                #default="{ errors }"
                immediate
                name="Nome"
                rules="required"
                vid="name"
              >
                <small class="v-err text-danger">{{ errors[0] }}</small>

                <querie-dropdown
                  v-if="!isUpdate"
                  @clear="clearContact"
                  @optionPicked="selectContact($event)"
                  :input-label="'Nome Completo'"
                  :select-label="'name'"
                  :second-select-label="'phone_number'"
                  :async-function="getContacts"
                  id="nome"
                  class="mb-1"
                  style="margin-top: 15px"
                  v-model="opportunity.contact.name"
                />

                <input-field-component
                  v-else
                  id="name"
                  placeholder="Nome Completo"
                  trim
                  type="text"
                  v-model="opportunity.contact.name"
                />
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                immediate
                name="celular"
                rules="required"
                vid="phone"
              >
                <small class="v-err text-danger">{{ errors[0] }}</small>

                <small class="text-danger" v-if="needDDD">
                  O número precisa conter algum DDD
                </small>

                <vue-phone-number-input
                  class="input-number"
                  required
                  size="lg"
                  valid-color="#046af3"
                  v-model="phoneNumber"
                  :border-radius="0"
                  :color="colorOfNumberInput"
                  :default-country-code="countryCode"
                  :show-code-on-list="true"
                  :translations="{
                    countrySelectorLabel: 'Código do país',
                    countrySelectorError: 'Escolha um país',
                    phoneNumberLabel: 'Celular',
                    example: 'Exemplo :',
                  }"
                  :disabled="isFromExternalCall || hasExternalContact"
                  @update="setMobileNumberInformation"
                />
              </validation-provider>

              <input-field-component
                id="email"
                placeholder="email@dominio.com.br"
                trim
                type="email"
                v-model="opportunity.contact.email"
              />

              <line-component :color="lineColor" style="margin: 20px 0" />

              <input-field-component
                v-if="isUpdate"
                :isStatic="true"
                @editClick="redirectToContacts()"
                id="contact"
                trim
                placeholder="Contato vinculado"
                v-model="opportunity.contact.name"
              />

              <span
                @click="showModalWithRoleCheck('bv-tranfer-opportunity')"
                class="cursor-pointer"
                v-if="!hasRequest && isUpdate"
              >
                <workspace-item
                  label="Workspace"
                  class="form-boxes"
                  variant="input"
                  v-model="currentWorkspace"
                />
              </span>

              <small class="v-err text-danger" v-if="userError">
                O campo Responsável é obrigatório
              </small>

              <span @click="showModalWithRoleCheck('bv-list_users')">
                <user-item
                  label="Responsável"
                  class="form-boxes"
                  variant="input"
                  v-if="!hasRequest"
                  v-model="currentUser"
                />
              </span>

              <user-item
                v-if="!hasRequest && opportunity.visit_generator && hasStreetGeneration"
                label="Gerador de Visita"
                class="form-boxes"
                variant="input"
                :disabled="true"
                v-model="opportunity.visit_generator"
              />

              <span
                class="step border justify-content-between align-items-center mb-1"
                @click="openModal('bv-list_products')"
                :id="opportunity.product_id"
              >
                <product-item v-if="product" :product="product" />

                <span v-else class="d-flex">
                  <span>Selecione {{ platform_terms.product_term }}</span>
                </span>

                <i class="icon-close" @click.stop="unsetProduct" v-if="product" />
                <i v-else class="icon-search" />
              </span>

              <b-form-group class="price-field">
                <money
                  @blur.native="checkForMoneyError(price)"
                  trim
                  id="price"
                  class="form-control price"
                  placeholder="Venda"
                  v-model="price"
                />

                <label class="floating"> {{ platform_terms.price_term }}: </label>
              </b-form-group>

              <div v-if="!isUpdate">
                <line-component :color="lineColor" style="margin: 15px 0" />

                <legend>Origem</legend>

                <validation-provider
                  #default="{ errors }"
                  immediate
                  name="Origem"
                  rules="required"
                  vid="source_type"
                >
                  <small class="v-err text-danger">{{ errors[0] }}</small>

                  <v-select
                    id="source_type"
                    label="title"
                    placeholder="Origem"
                    v-model="opportunity.source_type"
                    :options="source_types"
                    :reduce="(option) => option.id"
                  />
                </validation-provider>

                <legend>Mídia</legend>

                <validation-provider
                  #default="{ errors }"
                  immediate
                  name="Mídia"
                  rules="required"
                  vid="media"
                >
                  <small class="v-err text-danger">{{ errors[0] }}</small>
                  <v-select
                    id="media"
                    label="name"
                    placeholder="Mídia"
                    v-model="opportunity.media_id"
                    :options="medias"
                    :reduce="(option) => option.id"
                  />
                </validation-provider>

                <input-field-component
                  id="campaign_url"
                  placeholder="Site"
                  trim
                  type="text"
                  v-model="opportunity.campaign_url"
                />

                <legend>Campanha</legend>

                <validation-provider #default="{ errors }" immediate name="Campanha" vid="campaign">
                  <small class="v-err text-danger">{{ errors[0] }}</small>
                  <v-select
                    id="campaign"
                    label="title"
                    placeholder="Campanha"
                    v-model="opportunity.campaign_id"
                    :options="filtered_campaigns"
                    :reduce="(option) => option.id"
                  />
                </validation-provider>
              </div>

              <legend>Informações Complementares</legend>

              <date-time-picker
                date-only
                format="YYYY-MM-DD"
                formatted="DD/MM/YYYY"
                id="start_timestamp"
                placeholder="Data de Nascimento"
                v-model="opportunity.contact.birthdate"
                :no-button-now="true"
              />
              <input-field-component
                id="CPF"
                placeholder="CPF"
                trim
                type="text"
                v-model="document"
                :mask="['###.###.###-##']"
              />
              <input-field-component
                id="RG"
                placeholder="RG"
                trim
                type="text"
                v-model="opportunity.contact.id_number"
              />
              <legend>Endereço</legend>
              <input-field-component
                id="cep"
                placeholder="CEP"
                trim
                type="text"
                v-model="opportunity.contact.postal_code"
              />
              <input-field-component
                id="address_line_1"
                placeholder="Endereço"
                trim
                type="text"
                v-model="opportunity.contact.address_line1"
              />
              <input-field-component
                id="address_line_2"
                placeholder="Complemento"
                trim
                type="text"
                v-model="opportunity.contact.address_line2"
              />
              <v-select
                label="title"
                placeholder="Estado"
                :options="states"
                :reduce="(option) => option.id"
                v-model="opportunity.contact.state_code"
              />
              <input-field-component
                id="cidade"
                placeholder="Cidade"
                trim
                type="text"
                v-model="opportunity.contact.city_name"
              />
              <input-field-component
                id="bairro"
                placeholder="Bairro"
                trim
                type="text"
                v-model="opportunity.contact.neighborhood"
              />

              <legend>Redes Sociais</legend>

              <input-field-component
                id="instagram"
                placeholder="@instagram"
                trim
                type="text"
                v-model="opportunity.contact.instagram"
              />

              <input-field-component
                id="facebook"
                placeholder="@facebook"
                trim
                type="text"
                v-model="opportunity.contact.facebook"
              />

              <input-field-component
                id="linkedin"
                placeholder="@linkedIn"
                trim
                type="text"
                v-model="opportunity.contact.linkedin"
              />

              <input
                class="form-control"
                disabled
                id="created"
                placeholder="Criado em"
                type="text"
                v-if="isUpdate"
                :value="
                  this.$service.dateFormat(
                    this.opportunity.created_at,
                    'YYYY-MM-DD HH:mm:ss',
                    'DD/MM/YY'
                  )
                "
              />

              <div v-if="platformData.responsibleCheck">
                <legend>Dependentes</legend>

                <div
                  style="
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 20px;
                  "
                  :style="opportunity.is_responsible ? 'margin-bottom: 10px' : ''"
                >
                  <input
                    type="checkbox"
                    class="custom-checkbox"
                    v-model="opportunity.is_responsible"
                  />
                  Não é aluno?
                </div>
              </div>

              <validation-observer v-if="opportunity.is_responsible" ref="formValidation">
                <validation-provider
                  #default="{ errors }"
                  immediate
                  name="Nome"
                  :rules="opportunity.is_responsible ? 'required' : ''"
                  vid="name"
                >
                  <small class="v-err text-danger">{{ errors[0] }}</small>
                  <input-field-component
                    id="name"
                    placeholder="Nome Completo"
                    trim
                    type="text"
                    v-model="opportunity.student_name"
                  />
                </validation-provider>

                <validation-provider
                  #default="{ errors }"
                  immediate
                  name="CPF"
                  :rules="opportunity.is_responsible ? 'required' : ''"
                  vid="document"
                >
                  <small class="v-err text-danger">{{ errors[0] }}</small>
                  <input-field-component
                    id="CPF"
                    placeholder="CPF"
                    trim
                    type="text"
                    v-model="opportunity.student_document"
                    :mask="['###.###.###-##']"
                  />
                </validation-provider>
              </validation-observer>

              <b-form-textarea
                class="observation mt-1 mb-1"
                :style="showDescriptionError ? 'border-color: #EA5455' : ''"
                max-rows="2"
                placeholder="Observação"
                rows="2"
                v-model="opportunity.description"
              />
            </b-tab>

            <b-tab title="Origem/Mídia" :active="!showFirst" v-if="isUpdate" lazy>
              <legend>Origem</legend>

              <validation-provider
                #default="{ errors }"
                immediate
                name="Origem"
                rules="required"
                vid="source_type"
                v-if="isCardManual"
              >
                <small class="v-err text-danger">{{ errors[0] }}</small>

                <v-select
                  id="source_type"
                  label="title"
                  placeholder="Origem"
                  v-model="opportunity.source_type"
                  :options="source_types"
                  :reduce="(option) => option.id"
                />
              </validation-provider>

              <div @click="showBlockedContetError()" v-else class="blocked-content">
                {{ getBlockedContentName(opportunity.source_type) }}
              </div>

              <legend>Mídia</legend>

              <validation-provider
                #default="{ errors }"
                immediate
                name="Mídia"
                rules="required"
                vid="media"
                v-if="isCardManual"
              >
                <small class="v-err text-danger">{{ errors[0] }}</small>

                <v-select
                  id="media"
                  label="name"
                  placeholder="Mídia"
                  v-model="opportunity.media_id"
                  :options="medias"
                  :reduce="(option) => option.id"
                />
              </validation-provider>

              <div @click="showBlockedContetError()" v-else class="blocked-content mb-1">
                {{ opportunity?.media?.name }}
              </div>

              <legend>Campanha</legend>

              <validation-provider #default="{ errors }" immediate name="Campanha" vid="campaign">
                <small class="v-err text-danger">{{ errors[0] }}</small>
                <v-select
                  id="campaign"
                  label="title"
                  placeholder="Campanha"
                  v-model="opportunity.campaign_id"
                  :options="filtered_campaigns"
                  :reduce="(option) => option.id"
                />
              </validation-provider>

              <input-field-component
                id="campaign_url"
                placeholder="Site"
                trim
                type="text"
                v-model="opportunity.campaign_url"
              />

              <div v-if="role == 'admin'" @click="gotToInternalAdmin()" class="edit-lists-redirect">
                <i class="icon-pencil-line" />

                <div>Editar Listas</div>
              </div>
            </b-tab>

            <b-tab
              title="Histórico"
              :active="!showFirst"
              v-if="isUpdate && !isFromOpportunityAndContacts"
              lazy
            >
              <contact-history
                v-if="opportunity.contact"
                @refresh="emitEvents('saved')"
                :contact="opportunity.contact"
                id="contact-history"
              />
            </b-tab>
          </b-tabs>
        </div>

        <button
          type="submit"
          v-if="!hasSaveRequest"
          @click="save"
          :class="
            isFromOmnichannelDesktop ? 'save-button-click-omnichannel-desktop' : 'save-button-click'
          "
        >
          Salvar
        </button>

        <button
          class="save-button-click"
          v-if="hasSaveRequest"
          :class="{ noWidithLimitOmnichannel: isFromOmnichannelDesktop }"
        >
          <b-spinner />

          Aguarde...
        </button>
      </b-form>
    </validation-observer>

    <ClosingOpportunityModals
      @saveDeal="saveDeal($event)"
      @refresh="$emit('refresh')"
      :opportunityProp="opportunity"
    />

    <b-modal
      class="modal-dialog"
      hide-footer
      hide-header
      id="bv-update_kanban_stage"
      no-close-on-backdrop
      v-if="!hasRequest"
    >
      <sale-stage-selector
        :isUpdate="isUpdate"
        :opportunity="opportunity"
        :showUserSelector="false"
        :fromNewOpportunity="!isUpdate"
        @canceled="saveStageId"
        @changed="updateKanbanStage"
        @refresh="saveStageId"
        @saved="saveStageId"
        @newOpportunityStep="getNewOpportunityStage($event)"
      />
    </b-modal>

    <b-modal id="bv-enrollment_form" class="modal-dialog" hide-footer hide-header>
      <enrollment-form @closed="hideModal('bv-enrollment_form')" />
    </b-modal>

    <b-modal
      class="modal-dialog"
      id="bv-task_form_specific"
      hide-footer
      hide-header
      @hide="hideModal('bv-task_form_specific')"
    >
      <div class="d-block text-center">
        <task-form
          createdFrom="Opportunity"
          :isUpdate="false"
          :opportunity="opportunity"
          :parentId="opportunity.id"
          :isRegistryProp="isRegistry"
          :fromTaskWhatsapp="fromWhatsapp"
          :fromTaskCall="fromCall"
          :fromTaskEmail="fromEmail"
          @closed="hideModal('bv-task_form_specific')"
          @saved="savedTask($event)"
        />
      </div>
    </b-modal>

    <b-modal class="modal-dialog" id="bv-tranfer-opportunity" hide-footer hide-header>
      <transfer-workspace
        :opportunity="opportunity"
        @transfered="opportunityTransfered()"
        @closed="emitEvents('closed')"
      />
    </b-modal>

    <b-modal class="modal-dialog" id="bv-list_products" hide-footer hide-header>
      <div class="d-block text-center">
        <list-products @closed="hideModal('bv-list_products')" @selected="selectProduct" />
      </div>
    </b-modal>

    <b-modal class="modal-dialog" id="bv-list_users" hide-footer hide-header>
      <div class="d-block text-center">
        <user-list @closed="hideModal('bv-list_users')" @selected="selectUser" />
      </div>
    </b-modal>

    <b-modal class="modal-dialog" id="bv-help_modal" hide-footer hide-header>
      <div class="d-block text-center">
        <help type="rating" @closed="hideModal('bv-help_modal')" />
      </div>
    </b-modal>

    <b-modal id="bv-update_chat_modal" hide-footer hide-header>
      <chat-modal-component
        :fromOpportunity="true"
        :opportunity="this.opportunity"
        @clickEditOpportunityForm="openModal('bv-update_opportunity_modal')"
        @closed="hideModal('bv-update_chat_modal')"
      />
    </b-modal>

    <b-modal
      class="modal-dialog"
      hide-footer
      hide-header
      id="bv-data_correction"
      no-close-on-backdrop
    >
      <data-correction
        v-if="opportunity.id && totalStages"
        :opportunityId="opportunity.id"
        :isFrom="'Won'"
        @saved="saveDataCorrection($event)"
      />
    </b-modal>

    <b-alert
      class="m-1 p-2 fixed-bottom"
      dismissible
      variant="success"
      :show="dismissCountDown"
      @dismissed="dismissCountDown = 0"
    >
      <feather-icon icon="IconCheck"></feather-icon>Tarefa
      {{ currentTask.is_registry ? "registrada em" : "agendada para" }}
      {{ currentTask.start_timestamp }}
    </b-alert>
  </b-container>
</template>

<script>
import {
  BContainer,
  BCollapse,
  BFormGroup,
  BForm,
  BTabs,
  BTab,
  BButton,
  BSpinner,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import { Money } from "v-money";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import RateBar from "../components/RateBar.vue";
import SaleStageSelector from "../components/kanban/SaleStageSelector.vue";
import InputField from "@/views/components/InputField.vue";
import ButtonComponent from "@/views/components/ButtonComponent.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import Line from "@/views/components/Line.vue";
import TaskForm from "@/views/forms/TaskForm.vue";
import { integer } from "@/@core/utils/validations/validations";
import ListProducts from "../components/ListProducts.vue";
import ProductItem from "../components/product-item.vue";
import ContactSelector from "../components/ContactSelector.vue";
import DateTimePicker from "@/views/forms/components/DateTimePicker.vue";
import UserItem from "../components/UserItem.vue";
import UserList from "../components/UserList.vue";
import Help from "../components/Help.vue";
import ContactHistory from "../ContactHistory.vue";
import ChatModal from "@/views/components/chat/ChatModal.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { parsePhoneNumber } from "libphonenumber-js";
import tmaServices from "../../services/tmaServices.js";
import WorkspaceItem from "../components/WorkspaceItem.vue";
import TransferWorkspace from "../components/TransferWorkspace.vue";
import { errorHandling, verificationsForStepChange } from "@/mixins";
import DataCorrection from "../components/DataCorrection.vue";
import EnrollmentForm from "./EnrollmentForm.vue";
import ContractStatus from "./components/ContractStatus.vue";
import Vue from "vue";
import VueCookie from "vue-cookie";
import CardTopInfoComponent from "../components/Omnichannel/CardTopInfoComponent.vue";
import ClosingOpportunityModals from "@/views/components/ClosingOpportunityModals.vue";
import CloseOpportunityFromChat from "../components/CloseOpportunityFromChat.vue";
import { openPhoneChannel } from "@/services/callService";
import QuerieDropdown from "@/views/components/base/QuerieDropdown.vue";

Vue.use(VueCookie);

export default {
  components: {
    "input-field-component": InputField,
    "button-component": ButtonComponent,
    "chat-modal-component": ChatModal,
    "line-component": Line,
    BContainer,
    BFormGroup,
    BForm,
    BButton,
    BCollapse,
    BTab,
    BTabs,
    BSpinner,
    BFormTextarea,
    BAlert,
    ContractStatus,
    RateBar,
    SaleStageSelector,
    Money,
    FeatherIcon,
    UserList,
    vSelect,
    TaskForm,
    ListProducts,
    ProductItem,
    ContactSelector,
    DateTimePicker,
    UserItem,
    Help,
    ContactHistory,
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,
    WorkspaceItem,
    TransferWorkspace,
    DataCorrection,
    EnrollmentForm,
    CardTopInfoComponent,
    ClosingOpportunityModals,
    CloseOpportunityFromChat,
    QuerieDropdown,
  },

  props: {
    id: {
      type: integer,
      default: null,
    },

    isUpdate: {
      type: Boolean,
      default: false,
    },

    isOnlyHistoric: {
      type: Boolean,
      default: false,
    },

    isFromOmnichannelDesktop: {
      type: Boolean,
      default: false,
    },

    newestChat: {
      default: function () {
        return {};
      },
    },

    watchVerifyCancel: {
      type: Boolean,
      default: false,
    },

    watchSave: {
      type: Boolean,
      default: false,
    },

    isFromOpportunityAndContacts: {
      type: Boolean,
      default: false,
    },

    isFromExternalCall: {
      type: Boolean,
      default: false,
    },

    presetContact: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      reasons_won: [{ title: "Decidiu comprar agora", id: "Ready" }],

      opportunity: {
        name: "",
        state_type: "",
        contact: {
          name: "",
          email: "",
          phone_number: "",
          postal_code: "",
          address_line1: "",
          address_line2: "",
        },
        workspace: {
          name: "",
        },
      },
      currentTask: {},
      totalStages: {},
      columns: {},
      campaigns: {},
      product: null,
      currentUser: null,
      currentWorkspace: null,
      origOpportunity: null,
      hasRequest: true,
      hasSaveRequest: false,
      needDDD: false,
      showAlertTma: false,
      showFirst: true,
      userError: false,
      showWarningTma: false,
      showDefaultTma: false,
      dismissCountDown: 0,
      tma: "",
      document: "",
      price: 0,
      phoneNumber: "",
      editedPhoneNumber: "",
      currentOpportunity: "",
      editedOpportunity: "",
      colorOfNumberInput: "#d8d6de",
      countryCode: "BR",
      defaultDatetimeFormat: "DD/MM/YYYY HH:mm",
      lineColor: "#E5E5E5",
      showTaskOptionsCollapse: false,
      isRegistry: true,
      createdAtBuffer: null,
      showCloseChat: false,
      hasExternalContact: false,

      fromCall: false,
      fromEmail: false,
      fromWhatsapp: false,

      currentStep: {
        name: "",
        count: "",
      },

      blockedContentError: "Este campo está bloqueado por se tratar de um card externo",
      isCardManual: false,

      showDescriptionError: false,
    };
  },

  mixins: [errorHandling, verificationsForStepChange],

  async created() {
    this.$store.dispatch("whiteLabel/setPlatform");

    if (!this.isUpdate) {
      this.hasRequest = false;
    } else if (this.isFromOmnichannelDesktop) {
      await this.fetchOpportunity(this.$store.state.omnichannel.omniOpportunityId);
    } else {
      await this.fetchOpportunity(this.id);
    }

    if (!this.isUpdate && this.workspace.role === "salesRep") {
      this.selectUser(this.user);
    }

    await this.fetchTotalStages();
  },

  computed: {
    source_types() {
      const deepClone = require("lodash");

      let options = deepClone.cloneDeep(this.$store.getters["opportunityStore/getSourceTypes"]);

      let sources = Object.values(options);

      return sources.filter(
        (option) =>
          option.id != "URA" &&
          option.id != "Bella" &&
          option.id != "FormularioSite" &&
          option.id != "Importacao"
      );
    },

    platform_terms() {
      const platformData = this.$store.getters["whiteLabel/getPlatform"];

      return platformData.terms;
    },

    hasStreetGeneration() {
      const platformData = this.$store.getters["whiteLabel/getPlatform"];

      return platformData.has_street_generation;
    },

    reasons_lost_filtered() {
      const deepClone = require("lodash");

      let options = deepClone.cloneDeep(this.$store.getters["opportunityStore/getLostReasons"]);

      let lostReasons = Object.values(options);

      return lostReasons.filter((option) => option.id != "other");
    },

    reasons_lost() {
      const deepClone = require("lodash");

      let options = deepClone.cloneDeep(this.$store.getters["opportunityStore/getLostReasons"]);

      return options;
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },

    role() {
      return this.$store.getters["app/getUserRole"];
    },

    workspace() {
      return this.$store.getters["app/getUserWorkspace"];
    },

    platformData() {
      return this.$store.getters["whiteLabel/getPlatform"];
    },

    allCampaigns() {
      return this.$store.getters["opportunityStore/getCampaigns"];
    },

    states() {
      return this.$store.getters["opportunityStore/getSourceStates"];
    },

    filtered_campaigns() {
      if (!this.allCampaigns.length) {
        return [];
      }

      const filteredCampaigns = [];

      this.allCampaigns.forEach((campaign) => {
        filteredCampaigns.push({
          id: campaign.id,
          title: campaign.name,
        });
      });

      return filteredCampaigns;
    },

    medias() {
      return this.$store.getters["opportunityStore/getMedias"];
    },

    hasChanged() {
      return JSON.stringify(this.opportunity) != this.origOpportunity;
    },

    external_phone_status() {
      return this.$store.getters["externalPhone/getPhoneStatus"];
    },
  },

  mounted() {
    this.setPresetValuesFromPlatform();

    this.fetchCampaigns();
    this.fetchMedias();

    this.checkForPresetContact();
  },

  watch: {
    watchVerifyCancel(newSelected, oldSelected) {
      if (newSelected != oldSelected) {
        if (newSelected) {
          this.verifyCancel();
        }
      }
    },

    watchSave(newSelected, oldSelected) {
      if (newSelected != oldSelected) {
        if (newSelected) {
          this.save();
        }
      }
    },

    "opportunity.description": function (newSelected, oldSelected) {
      if (newSelected != oldSelected && newSelected.length <= 10000) {
        this.showDescriptionError = false;
      }
    },
  },

  methods: {
    emitEvents(event) {
      this.$emit(`${event}`);
    },

    gotToInternalAdmin() {
      this.$router.push("/lists");
    },

    terminateCall() {
      this.$store.commit("externalPhone/TRIGGER_END_CALL");
    },

    changeStoreSingleOpportunityID(id) {
      this.$store.commit("externalPhone/CHANGE_SINGLE_OPPORTUNITY_ID", id);
    },

    changeStoreIsDialing(status) {
      this.$store.commit("externalPhone/CHANGE_IS_DIALING", status);
    },

    changeStoreStatus(status) {
      this.$store.commit("externalPhone/CHANGE_STATUS", status);
    },

    changeStoreIsSingularCall(status) {
      this.$store.commit("externalPhone/CHANGE_IS_SINGULAR_CALL", status);
    },

    changeOpName(name) {
      this.opportunity.name = name;
    },

    openEnroll() {
      this.$store.dispatch("contract/setOpportunityForContract", this.opportunity);

      this.openModal("bv-enrollment_form");
    },

    handleKeydown(event) {
      if (event.key === "Enter") {
        event.preventDefault();

        if (!this.hasSaveRequest) {
          this.save();
        }
      }
    },

    taskFromWhats() {
      this.cleanValues();
      this.fromWhatsapp = true;
      this.openModal("bv-task_form");
    },

    taskFromCall() {
      openPhoneChannel(this.opportunity.contact.phone_number);

      this.cleanValues();
      this.fromCall = true;
      this.openModal("bv-task_form");
    },

    showBlockedContetError() {
      this.$bvToast.toast(this.blockedContentError, {
        title: "Campo Bloqueado",
        variant: "warning",
        solid: true,
      });
    },

    checkForPresetContact() {
      if (this.presetContact) {
        this.opportunity.contact = this.presetContact;
        this.phoneNumber = this.presetContact.phone_number;
        this.hasExternalContact = true;
      }
    },

    selectContact(event) {
      if (event?.phone_number) {
        this.opportunity.contact = { ...event };
        this.phoneNumber = event.phone_number;
        this.hasExternalContact = true;
      }
    },

    clearContact() {
      this.$service.resetObjectFieldsToUndefined(this.opportunity.contact);
      this.phoneNumber = "";

      this.hasExternalContact = false;
    },

    redirectToContacts() {
      this.$store.commit(
        "contactStore/setContactIdFromOpportunityRedirect",
        this.opportunity.contact.id
      );

      this.$router.push(`${"/contacts"}`);
    },

    singleVoipCall() {
      this.$store
        .dispatch("externalPhone/getSipCredentials", this.user.id)
        .then((response) => {
          if (response.data.voip_username && response.data.voip_password) {
            this.$store.commit("externalPhone/CHANGE_USER_CREDENTIALS", {
              user_name: response.data.voip_username,
              password: response.data.voip_password,
            });

            this.changeStoreSingleOpportunityID(this.opportunity.id);
            this.changeStoreIsSingularCall(true);
            this.changeStoreIsDialing(true);

            this.changeStoreStatus("onHold");

            this.$store.commit("externalPhone/TRIGGER_START_CALLS");
          } else {
            this.$bvToast.toast("Usuário sem credenciais de VoIP neste workspace", {
              title: "Usuário sem acesso",
              variant: "warning",
              solid: true,
            });
          }
        })
        .catch((error) => {
          this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
        });
    },

    taskFromEmail() {
      this.cleanValues();
      this.fromEmail = true;
      this.openModal("bv-task_form");
    },

    cleanValues() {
      this.fromWhatsapp = false;
      this.fromCall = false;
      this.fromEmail = false;
    },

    setPresetValuesFromPlatform() {
      if (this.platformData.preset_values && !this.isUpdate) {
        this.opportunity.source_type = this.platformData.preset_values.source_type;

        this.opportunity.media_id = this.platformData.preset_values.media_id;
      }
    },

    opportunityTransfered() {
      const currentPath = this.$route.path;

      if (currentPath == "/omnichannel") {
        this.emitEvents("opportunityTransfered");
      } else {
        this.emitEvents("closed");
      }
    },

    checkIfCardIsManual() {
      const allPossibleAutomaticSources = ["URA", "Bella", "FormularioSite"];

      if (allPossibleAutomaticSources.includes(this.opportunity.source_type)) {
        this.isCardManual = false;

        return;
      }

      this.isCardManual = true;
    },

    getFormatedClosedType(closedType) {
      let lostReason;

      this.reasons_lost.forEach((element) => {
        if (element.id == closedType) {
          lostReason = element.title;
        }
      });

      return lostReason;
    },

    getBlockedContentName(blockedContent) {
      const blockedContents = {
        Bella: "Bella",
        FormularioSite: "Formulário Site",
        URA: "URA",
      };

      return blockedContents[blockedContent];
    },

    async fetchTotalStages() {
      this.totalStages = await this.$store.dispatch(
        "opportunityStore/getStages",
        this.isUpdate ? this.opportunity.workspace_id : null
      );

      this.columns = this.totalStages;

      if (!this.isUpdate) {
        const firstStep = this.totalStages.filter((stage) => stage.type == "First");

        this.currentStep.title = firstStep[0].title;
        this.getValuesForCurrentStep(firstStep[0].id);
      }
    },

    getNewOpportunityStage($event) {
      this.getValuesForCurrentStep($event);

      this.currentStep.id = $event;

      this.hideModal("bv-update_kanban_stage");
    },

    openModal(modal) {
      this.$bvModal.show(`${modal}`);
    },

    closeByChat() {
      this.showCloseChat = true;
    },

    checkContractStatus() {
      if (this.opportunity.contract) {
        if (this.opportunity.contract.payment_status == "Canceled") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    openTaskModal(isRegistry) {
      this.showTaskOptionsCollapse = !this.showTaskOptionsCollapse;
      this.isRegistry = isRegistry;

      this.$bvModal.show("bv-task_form_specific");
    },

    hideModal(modal) {
      this.$bvModal.hide(`${modal}`);
    },

    closeHalfModal() {
      this.showCloseChat = false;
    },

    updateRating(rating) {
      this.opportunity.probability = rating;
    },

    setIsClosed(status) {
      return this.opportunity.state_type.toLowerCase() === status;
    },

    checkForMoneyError(value) {
      if (value < 0) {
        this.price = 0;
      } else if (value > 9999999.99) {
        this.price = 9999999.99;
      }
    },

    closeDeal(state) {
      let stateType = this.opportunity.state_type;

      if (stateType === "Open") {
        this.changeSaleStage(state);

        this.MIXIN_verificationsForStepChange();
        return;
      }

      if (stateType === "Won" || stateType === "Lost") {
        if (stateType == state) {
          this.openModal("bv-update_kanban_stage");
        } else {
          this.changeSaleStage(state);
          this.MIXIN_verificationsForStepChange();
        }
      }
    },

    checkImNicToken() {
      if (localStorage.getItem("imNicToken")) {
        return true;
      } else {
        return false;
      }
    },

    saveDataCorrection(eventInfo) {
      if (eventInfo.product_id || eventInfo.price) {
        this.opportunity.product_id = eventInfo.product_id;
        this.opportunity.price = eventInfo.price;
      }

      this.MIXIN_saveDataCorrection();
    },

    saveDeal(event) {
      if (event.opportunity) {
        this.getObjectAttributes(event.opportunity, this.opportunity);
      }

      this.hideModal(`bv-close_deal_${event.state.toLowerCase()}`);

      this.MIXIN_saveDeal(event.state);
    },

    getObjectAttributes(source, modifiedObject) {
      for (let attribute in source) {
        if (source.hasOwnProperty(attribute)) {
          modifiedObject[attribute] = source[attribute];
        }
      }
    },

    shadeColor(color, percent) {
      color = this.checkIfhasHash(color);

      var R = parseInt(color.substring(1, 3), 16);
      var G = parseInt(color.substring(3, 5), 16);
      var B = parseInt(color.substring(5, 7), 16);

      R = parseInt((R * (100 + percent)) / 100);
      G = parseInt((G * (100 + percent)) / 100);
      B = parseInt((B * (100 + percent)) / 100);

      R = R < 255 ? R : 255;
      G = G < 255 ? G : 255;
      B = B < 255 ? B : 255;

      R = Math.round(R);
      G = Math.round(G);
      B = Math.round(B);

      var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
      var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
      var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

      return "#" + RR + GG + BB;
    },

    checkIfhasHash(color) {
      if (!color) {
        return;
      }

      if (color[0] == "#") {
        return color;
      } else {
        return "#" + color;
      }
    },

    getValuesForCurrentStep(stepId) {
      if (!this.isUpdate) {
        const currentStage = this.totalStages.filter((s) => s.id === stepId).shift();

        if (currentStage) {
          this.currentStep = currentStage.step;

          this.currentStep.title = currentStage.title;

          this.currentStep.color = currentStage.color;

          this.currentStep.borderColor = this.shadeColor(this.currentStep.color, -25);
        }

        return;
      }

      this.currentStep = {
        title: this.opportunity?.sales_stage?.name,
        color: this.opportunity?.sales_stage?.color,
        borderColor: this.shadeColor(this.opportunity?.sales_stage?.color, -25),
      };
    },

    changeSaleStage(stateType) {
      if (stateType === "lost" && !this.opportunity.closed_type) {
        this.closedTypeError = true;
        return;
      }

      if (stateType.toLowerCase() == "won") {
        this.opportunity.sales_stage_id = this.getIdOfStep("Won");
      }

      if (stateType.toLowerCase() == "lost") {
        this.opportunity.sales_stage_id = this.getIdOfStep("Lost");
      }

      if (stateType.toLowerCase() == "open") {
        this.opportunity.sales_stage_id = this.getIdOfStep("Default");
      }

      this.hideModal(`bv-close_deal_${stateType.toLowerCase()}`);
    },

    getIdOfStep(stepType) {
      let stepId = null;

      this.totalStages.forEach((element) => {
        if (element.type == stepType) {
          stepId = element.id;
        }
      });

      return stepId;
    },

    unsetProduct() {
      this.product = null;
    },

    triggerBoardChange() {
      this.currentOpportunity = this;
      this.openModal("bv-update_kanban_stage");
    },

    setMobileNumberInformation(update) {
      this.countryCode = update.countryCode;
      this.editedPhoneNumber = update.formatNational;

      if (
        this.editedPhoneNumber &&
        this.editedPhoneNumber.length < 10 &&
        this.countryCode === "BR"
      ) {
        this.colorOfNumberInput = "#ea5556";
        this.needDDD = true;
      } else {
        this.needDDD = false;
      }
    },

    showModalWithRoleCheck(modal) {
      if (this.workspace.role !== "salesRep" && !this.isFromExternalCall) {
        this.openModal(modal);
      }
    },

    assigningValuesToSave(opportunity) {
      opportunity.contact.document = this.document;
      opportunity.contact.phone_number = this.phoneNumber;
      opportunity.price = this.price;
      opportunity.contact.country_code = this.countryCode;
      opportunity.user = this.currentUser;

      if (this.editedPhoneNumber !== "") {
        opportunity.contact.phone_number = this.editedPhoneNumber;
      }

      if (this.hasExternalContact) {
        opportunity.contact_id = this.opportunity.contact.id;
      }
    },

    save() {
      const { opportunity } = this;
      this.assigningValuesToSave(opportunity);
      const formRefs = this.$refs.formValidation;

      if (this.needDDD) {
        return;
      }

      if (!opportunity.user_id) {
        this.userError = true;
        return;
      }

      formRefs.validate().then((success) => {
        if (success) {
          this.hasSaveRequest = true;

          if (!this.isUpdate && !this.opportunity.name) {
            opportunity.name = opportunity.contact.name;
          }

          opportunity.email = opportunity.contact.email;
          opportunity.phone_number = opportunity.contact.phone_number;

          if (!this.isUpdate) {
            opportunity.state_type = "Open";
          } else {
            this.createdAtBuffer = opportunity.created_at;

            delete opportunity.created_at;
            delete opportunity.updated_at;
            delete opportunity.contact.created_at;
            delete opportunity.contact.updated_at;
          }

          if (this.workspace.role === "salesRep") {
            opportunity.user_id = this.user.id;
          }

          if (!this.isUpdate) {
            opportunity.sales_stage_id = this.currentStep.id;
          }

          this.$store
            .dispatch("opportunityStore/save", opportunity)
            .then(() => {
              this.emitEvents("saved");
            })
            .catch((error) => {
              this.hasSaveRequest = false;

              this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);

              if (
                error.response.data.message ==
                '"Description" deve conter no máximo 10.000 caracteres'
              ) {
                this.showDescriptionError = true;
              }
            });
        } else {
          const el = document.querySelector(".v-err");
          el.scrollIntoView();
          return;
        }
      });
    },

    saveStageId() {
      this.hideModal("bv-update_kanban_stage");
      this.emitEvents("saved");
    },

    updateKanbanStage(opportunity) {
      this.opportunity.sales_stage_id = opportunity.sales_stage_id;
      this.hideModal("bv-update_kanban_stage");
    },

    savedTask(task) {
      this.currentTask = task;

      this.currentTask.start_timestamp = moment(
        this.currentTask.start_timestamp,
        "YYYY-MM-DD HH:mm:ss"
      ).format(this.defaultDatetimeFormat);

      this.dismissCountDown = 6;

      this.hideModal("bv-task_form_specific");

      if (this.opportunity.state_type == "Open") {
        this.MIXIN_saveTaskForm();
      }
    },

    close(state) {
      this.$bvModal.show(`bv-close_deal_${state}`);
      this.opportunity.state_type = state;
      this.opportunity.closed_at = moment().format("YYYY-MM-DD HH:mm");

      this.$store.dispatch("opportunityStore/close", this.opportunity).then(() => {
        this.emitEvents("saved");
      });

      return false;
    },

    selectProduct(product) {
      this.product = product;
      this.opportunity.product_id = product.id;

      if (this.opportunity.price == 0 || this.price == 0) {
        this.price = product.price;
      }

      this.hideModal("bv-list_products");
    },

    selectUser(user) {
      this.currentUser = user;
      this.opportunity.user_id = user.id;
      this.opportunity.user = user;
      this.hideModal("bv-list_users");
    },

    fetchCampaigns() {
      if (this.allCampaigns.length !== 0) {
        return;
      }

      this.$store.dispatch("opportunityStore/fetchCampaigns");
    },

    fetchMedias() {
      if (this.medias.length !== 0) {
        return;
      }

      this.$store.dispatch("opportunityStore/fetchMedias");
    },

    assignmentsForCancellationVerification(opportunity) {
      this.currentUser = opportunity.user;
      this.currentWorkspace = opportunity.workspace;
      this.phoneNumber = opportunity.contact.phone_number;
      this.document = opportunity.contact.document;
      this.price = opportunity.price;
      this.currentOpportunity = JSON.stringify(this.opportunity);
      this.currentOpportunity = JSON.parse(this.currentOpportunity);
    },

    async fetchOpportunity(id) {
      let salesStageId;

      try {
        const response = await this.$store.dispatch("opportunityStore/fetchOne", id);

        if (response.data.length === 0) {
          return;
        }

        this.opportunity = response.data;

        this.checkIfCardIsManual();

        if (!this.opportunity.price) {
          this.opportunity.price = 0;
        }

        this.origOpportunity = JSON.stringify(this.opportunity);

        if (this.opportunity.product_id) {
          this.product = this.opportunity.product;
        }

        this.assignmentsForCancellationVerification(this.opportunity);

        if (this.opportunity.contact.country_code !== null) {
          this.countryCode = this.opportunity.contact.country_code;
        }

        this.setFormatedTma(this.opportunity);

        salesStageId = this.opportunity.sales_stage_id;

        if (this.phoneNumber) {
          this.phoneNumber = parsePhoneNumber(this.phoneNumber, this.countryCode).formatNational();
        }
      } catch (error) {
        this.hasRequest = false;
        this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
      } finally {
        this.hasRequest = false;
        const stepId = salesStageId || this.totalStages[0].id;

        this.getValuesForCurrentStep(stepId);

        if (this.isOnlyHistoric) {
          this.showFirst = false;
          setTimeout(() => {
            document.querySelector("#contact-history").scrollIntoView({ behavior: "smooth" });
          }, 1000);
        }
      }
    },

    verifyCancel() {
      this.editedOpportunity = JSON.parse(JSON.stringify(this.opportunity));

      if (this.document) {
        this.editedOpportunity.contact.document = this.document.replace(/[-.]/g, "");
      }

      if (this.price) {
        this.editedOpportunity.price = parseFloat(this.price);
      }

      this.editedOpportunity.contact.phone_number = this.phoneNumber.replace(/[()-\s]/g, "");

      if (typeof this.currentOpportunity.price === "string") {
        this.currentOpportunity.price = parseFloat(this.currentOpportunity.price.replace(",", "."));
      }

      if (JSON.stringify(this.currentOpportunity) === JSON.stringify(this.editedOpportunity)) {
        this.emitEvents("closed");
      } else {
        this.openModal("bv-verify-cancel-opportunity");
      }
    },

    setFormatedTma(opportunity) {
      let tma = opportunity.tma;

      if (!tma) {
        let startTimestamp = opportunity.recreated_at ?? opportunity.created_at;
        startTimestamp = new Date(startTimestamp).getTime() / 1000;
        let currentTimestamp = new Date().getTime() / 1000;
        let calculatedTmaWithNoInteraction = currentTimestamp - startTimestamp;
        this.setTmaWarnings(calculatedTmaWithNoInteraction);
        this.tma = tmaServices.formatter(calculatedTmaWithNoInteraction);
        return;
      }

      this.setTmaWarnings(tma);
      this.tma = tmaServices.formatter(tma);
    },

    setTmaWarnings(tma) {
      const tmaIndentificator = tmaServices.formatterWithIndentificator(tma);

      if (tmaIndentificator == "acceptableTime") {
        this.showAlertTma = false;
        this.showWarningTma = false;
        this.showDefaultTma = true;

        if (!this.opportunity.tma) {
          this.showDefaultTma = false;
        }
      } else if (tmaIndentificator == "attentionTime") {
        this.showWarningTma = false;
        this.showAlertTma = true;
        this.showDefaultTma = false;

        if (this.opportunity.tma && tma > 600 && tma < 1800) {
          this.showWarningTma = true;
          this.showAlertTma = false;
          this.showDefaultTma = false;
        }
      } else if (tmaIndentificator == "exceededTime") {
        this.showWarningTma = false;
        this.showAlertTma = true;
        this.showDefaultTma = false;

        if (this.opportunity.tma && tma > 600) {
          this.showWarningTma = true;
          this.showAlertTma = false;
          this.showDefaultTma = false;
        }
      }
    },

    async getContacts(search) {
      const defaultParams = [
        { query: "search", param: search },
        { query: "limit", param: 25 },
      ];

      try {
        const response = await this.$store.dispatch(
          "contactStore/list",
          this.$service.formatQueryParam(defaultParams)
        );

        return response.data;
      } catch (error) {
        this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);

        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.general-container {
  padding: 0;

  :deep(#icons-menu__BV_tab_controls_) {
    display: none;
  }

  .loading-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
  }

  .noPaddingOmnichannel {
    padding-top: 0 !important;
  }

  .middle-container {
    direction: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 20px;
    padding-top: 10px;
  }

  .icon-close {
    cursor: pointer;
  }

  .margin-form {
    margin-bottom: 50px;
  }

  .margin-form-omnichannel {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .top-menu {
    background: #fff;
    display: block;
    height: 50px;
    max-width: 500px;
    position: fixed;
    width: 100vw;
    border: 1px solid rgba(0, 0, 0, 0.207);
    z-index: 1005;

    .action-buttons {
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      color: #14223b;
      display: flex;
      height: 100%;
      justify-content: space-between;
      padding: 0 1.2rem;
      width: 100%;
    }
  }

  .tma-dates {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 15px;
    border: 1px solid #dcdbe2;
    border-radius: 6px;
    padding: 0 12px;

    @media (max-width: 450px) {
      padding: 0 2px;
    }

    #createdDate {
      font-size: 12px;
      justify-self: flex-start;
    }

    #updatedDate {
      display: flex;
      flex-direction: row;

      .thermomether {
        width: 12px;
        margin-right: 15px;

        @media (max-width: 450px) {
          margin-right: 8px;
        }
      }

      .checkGroup {
        width: 18px;
        margin-right: 15px;
      }

      div {
        display: flex;
        align-items: center;

        .showAlertTma {
          font-size: 13px;
          color: #f7685b;
        }

        .hideAlertTma {
          font-size: 13px;
          color: #046af3;
        }
      }
    }
  }

  .tmaDatesWarning {
    border: 1px solid #f7685b;
  }

  .rating {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 0.48em;

    label {
      font-size: 1rem;
    }

    .feather {
      color: #046af3;
      fill: #d4e2f3;
      margin-top: -4px;
    }
  }

  .state-type-action {
    display: flex;
    flex-flow: row;
    padding: 0.438rem 0rem;

    & > .button-component {
      display: flex;
      border-radius: 6px;
      width: 50%;
      text-align: center;
      justify-content: center;
      padding: 0.438rem 1rem;
    }

    & > .button-component:first-child {
      border: 1px solid #d8d6de;
      color: #e11;
      border-right: none;
      border-top-left-radius: 5px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 0px;

      &.selected {
        background-color: red;
        color: #fff;
      }
    }

    & > .button-component:last-child {
      border: 1px solid #d8d6de;
      border-top-right-radius: 5px;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 0px;

      &.selected {
        background-color: #046af3;
        color: #fff;
      }
    }
  }

  .enrollment-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 100%;
    padding: 0.7rem 0;
    border-radius: 5px;
    border: solid 1px #046af3;
    cursor: pointer;
  }

  .state-type-action-message {
    background: #ffbbc7;
    border: 1px solid #ff2c55;
    border-radius: 4px;
    height: auto;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    h4 {
      color: #000;
      text-align: start;
      padding: 5px 10px 0px 10px;
      font-size: 16px;
    }

    span {
      color: #767383;
      text-align: start;
      padding: 0px 10px 0px 10px;
    }
  }

  legend {
    color: #000;
    text-align: left;
    font-size: 1em;
    padding-top: 0.48em;
    margin-bottom: 1em;
  }

  .step {
    display: flex;
    align-items: stretch;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    margin-top: 7px;
    margin-bottom: 6px;
    padding: 13px;
    border-radius: 4px;
    color: #252525;
    cursor: pointer;
  }

  .form-boxes {
    margin-top: 7px;
    padding: 5px;
    border-radius: 5px;
  }

  .price-field {
    .floating {
      float: left;
      font-size: 15px;
      margin-top: -30px;
      margin-left: 16px;
      color: #535e68;
    }
  }

  .price {
    text-align: right;
    background: transparent;
    color: black;
    font-weight: 600;
  }

  .add-task-button {
    width: 100%;
    margin-top: 7px;
    padding: 15px;
    transition: 0.4s background ease-in-out;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.2) !important;
    border: none;
  }

  .add-task-button:hover {
    background: #4594ff !important;
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.25) !important;
  }

  .input-number {
    margin-bottom: 1rem;
    z-index: 1;
  }

  #source_type {
    transition: z-index 0.2s;
    z-index: 0;
  }

  .vs--open {
    z-index: 1000 !important;
  }

  .custom-checkbox {
    height: 18px;
    width: 18px;
    margin-right: 5px;
    border-radius: 2px;
    border: solid 1px #046af3;
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
  }

  .custom-checkbox:checked {
    background-color: blue;
    background-image: url("../../assets/images/icons/Checkmark.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.modal-close-deal {
  display: flex;

  h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #000000;
  }

  .buttons {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;

    button {
      min-width: 100px;
      border-radius: 6px;
      padding: 13px 0;
      text-align: center;
      margin: 0 5px !important;
    }

    button:last-child {
      margin-right: 0px;
    }
  }
}

.save-button-click {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  font-weight: bold;
  height: 50px;
  width: 100% !important;
  max-width: 500px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  gap: 7px;
  border: none !important;
  border-top: 1px solid rgba(0, 0, 0, 0.207) !important;
  background: #fff !important;
  color: #000 !important;
  cursor: pointer;
}

.save-button-click-omnichannel-desktop {
  position: absolute;
  font-weight: bold;
  height: 50px;
  width: calc(33vw - 30px);
  z-index: 2000;
  bottom: 0;
  right: 0;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.207);
  background: #ffffff;
  color: #000000;
  cursor: pointer;
}

.noWidithLimitOmnichannel {
  max-width: none;
}

.task-collapse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 175px;
  padding: 1rem;
  background: #ffffff;
  border: solid 1px #93abbe;
  border-radius: 10px;
  cursor: auto;

  .task-collapse-title {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
  }

  .task-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }

  .task-register {
    margin-top: 15px;
    border: solid 1px;
    border-left: solid 5px;
    border-color: #2ed47a !important;
  }

  .task-schedule {
    margin-top: 10px;
    border: solid 1px;
    border-left: solid 5px;
    border-color: #2196f3 !important;
  }
}

.call-component-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1.5rem 10px 1.5rem;
  gap: 10px;

  .call-ongoing {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    height: 46px;
    width: 57%;
    gap: 10px;
    border-radius: 10px;
    border: solid 1px #2ed47a80;
    background: #f5fdf8;
    color: #000000;
  }

  .white-green-circle {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    border-radius: 100%;
    border: solid 4px #2ed47a;
    background: #ffffff;
  }

  .dial-stop {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    height: 46px;
    width: 40%;
    gap: 5px;
    border-radius: 10px;
    border: none;
    background: #ff0025 !important;
    color: #ffffff;
  }

  .warning-text {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #4c5862;
  }
}

.blocked-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  background: #e5e5e5;
  border: solid 1px #d2d2d2;
}

.edit-lists-redirect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  gap: 5px;
  border-radius: 8px;
  border: solid 1px #046af3;
  color: #046af3;
  cursor: pointer;
}

.contact-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  padding: 14px 20px;
  border: 1px solid #d2d2d2;
  color: #4c5862;
  cursor: pointer;
}
</style>

<template>
  <div class="info-component">
    <div class="first-row-info">
      <div @click="openOpportunityFromTask()" class="d-flex" style="align-items: center">
        <avatar :avatarLetter="$service.getFirstLetter(opportunity.name)" :size="3" />

        <div class="d-flex" style="flex-direction: column; text-align: left">
          <div v-if="!isEditingOpName" @click="editOpportunityName()" class="opportunity-name">
            {{ opportunity.name }}

            <i v-if="editable" class="icon-pencil-line hovering-edit" />
          </div>

          <input
            v-else
            @blur="editOpportunityName()"
            id="op-name-input"
            class="op-name-input"
            type="text"
            v-model="opportunity.name"
          />

          <div style="color: #046af3; font-size: 12px">
            {{ "#" + opportunity.id }}
          </div>
        </div>
      </div>

      <div class="third-row-buttons">
        <b-dropdown id="drop-actions">
          <template #button-content>
            <i class="icon-phone-chat-icon" style="font-size: 18px" />
          </template>

          <b-dropdown-item @click="goToWhatsapp">
            <i class="icon-whatsapp-chat-icon" style="font-size: 18px; color: #2ed47a" />

            Ir para Whatsapp
          </b-dropdown-item>

          <b-dropdown-item v-if="!fromTaskForm && !isIOS" @click="triggerSingleVoipCall()">
            <i class="icon-phone-chat-icon" style="font-size: 18px" />

            Realizar Ligação (Voip)
          </b-dropdown-item>

          <b-dropdown-item v-if="!fromTaskForm" @click="openCall()">
            <i class="icon-phone-chat-icon" style="font-size: 18px" />

            Realizar Ligação
          </b-dropdown-item>

          <b-dropdown-item
            v-if="opportunity.contact.email && !fromTaskForm"
            @click="openEmail(opportunity.contact.email)"
          >
            <i class="icon-email-svgrepo-com" style="font-size: 18px" />

            Enviar E-mail
          </b-dropdown-item>

          <b-dropdown-item @click="copyNumber(opportunity.contact.phone_number)">
            <i class="icon-copy" style="font-size: 19px" />

            Copiar Número
          </b-dropdown-item>
        </b-dropdown>

        <b-button
          v-if="isOpenRoom && !fromTaskForm && checkPermissionInRoom()"
          @click="closeOpportunity"
          class="button-style"
          style="background: #046af3 !important"
        >
          <i class="icon-check" />
        </b-button>

        <b-button
          v-if="fromTaskForm && task.status != 'Completed'"
          @click="closeTask()"
          class="button-style"
          style="background: #046af3 !important"
        >
          <i class="icon-check" />
        </b-button>
      </div>
    </div>

    <div class="d-flex w-100" style="gap: 25px">
      <div class="second-row-info" v-if="fromChat">
        <div style="display: flex; align-items: center">
          <i class="icon-imbot" style="font-size: 16px; margin-left: -3px; margin-right: 3px" />

          {{ newestChat.bot_name }}
        </div>

        <div style="display: flex; align-items: center">
          <i class="icon-information-circle-outline" style="margin-right: 5px" />

          {{ "Sala: #" + newestChat.id + " | " + "Status: " + (isOpenRoom ? "Aberta" : "Fechada") }}
        </div>
      </div>

      <div class="second-row-info" v-if="!fromChat">
        <div style="display: flex; align-items: center">
          <feather-icon icon="Share2Icon" size="12" style="margin-right: 5px" />

          {{ opportunity.media ? opportunity.media.name : "Sem mídia" }}
        </div>

        <div style="display: flex; align-items: center">
          <i class="icon-icon-notebook" style="margin-right: 5px" />

          {{ opportunity.workspace.name }}
        </div>
      </div>

      <div class="second-row-when-chat" v-if="!fromChat && newestChat">
        <div style="display: flex; align-items: center">
          <i class="icon-imbot" style="font-size: 16px; margin-left: -3px; margin-right: 3px" />

          {{ newestChat.bot_name }}
        </div>

        <div style="display: flex; align-items: center">
          <i class="icon-information-circle-outline" style="margin-right: 5px" />

          {{ "Sala: #" + newestChat.id + " | " + "Status: " + (isOpenRoom ? "Aberta" : "Fechada") }}
        </div>
      </div>
    </div>

    <confirm
      v-if="fromTaskForm"
      @saved="completeTask"
      @closed="resetConfirmCompletion"
      :visible="confirmCompletion.show"
      :title="confirmCompletion.title"
    >
      <h2 class="text-center d-flex flex-row align-center justify-content-center">
        <feather-icon class="circle" icon="CheckIcon"> </feather-icon>

        &nbsp;Tarefa Concluída
      </h2>

      <v-select
        v-model="task.ended_type"
        :reduce="(option) => option.id"
        :options="endedTypes"
        label="title"
        placeholder="Qual foi a forma de atendimento?"
      />

      <div
        :class="`ended-scheduled-check ${collapseSchedule ? '' : 'mb-1'}`"
        :style="`border-bottom: ${collapseSchedule ? 'none' : '1px solid #5A9DF7'}; 
              
              border-bottom-left-radius: ${collapseSchedule ? '0px' : '3px'}; 
              
              border-bottom-right-radius: ${collapseSchedule ? '0px' : '3px'};`"
        @click="toggleEndedScheduled()"
      >
        <i class="icon-calendar mr-1" />

        Realizar Agendamento
      </div>

      <b-collapse id="collapse-1" class="mb-1" v-model="collapseSchedule">
        <div
          style="
            border-left: 1px solid #046af3;
            border-bottom: 1px solid #046af3;
            border-right: 1px solid #046af3;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            padding: 10px;
          "
        >
          <small class="text-danger" v-if="userForSchedulingError">
            O campo Responsável é obrigatório
          </small>

          <validation-observer ref="taskScheduleValidation">
            <span
              @click="showUserModal(true)"
              class="d-flex"
              :style="userForSchedulingError ? 'margin-top: 5px;' : ''"
            >
              <user-item
                label="Responsável"
                variant="input"
                :selected="scheduleTask.user"
                v-model="scheduleTask.user"
              />
            </span>

            <validation-provider #default="{ errors }" name="ação" vid="actions" rules="required">
              <small class="text-danger">{{ errors[0] }}</small>

              <v-select
                label="title"
                placeholder="Ação"
                :reduce="(option) => option.id"
                :options="types"
                v-model="scheduleTask.type"
              >
                <template #option="{ title, hasTpo }">
                  <div style="display: flex; justify-content: space-between">
                    <em style="font-style: normal">{{ title }}</em>

                    <div
                      v-if="hasTpo"
                      style="background: #046af3; padding: 3px 5px; border-radius: 3px"
                    >
                      <em style="color: #fff; font-style: normal">
                        {{ hasTpo ? "TPO" : "" }}
                      </em>
                    </div>
                  </div>
                </template>

                <template #selected-option="{ title, hasTpo }">
                  <div
                    style="
                      display: flex;
                      width: 65vw;
                      max-width: 340px;
                      justify-content: space-between;
                    "
                  >
                    <em style="font-style: normal">{{ title }}</em>

                    <div
                      v-if="hasTpo"
                      style="background: #046af3; padding: 3px 5px; border-radius: 3px"
                    >
                      <em style="color: #fff; font-style: normal">
                        {{ hasTpo ? "TPO" : "" }}
                      </em>
                    </div>
                  </div>
                </template>
              </v-select>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="Data da Tarefa"
              vid="taskDate"
              rules="required"
            >
              <small class="text-danger">{{ errors[0] }}</small>
              <!--Date -->
              <date-time-picker
                id="taskDate"
                placeholder="Data da Tarefa"
                format="YYYY-MM-DD"
                formatted="DD/MM/YYYY"
                date-only
                :minDate="moment()"
                :maxDate="moment().add(18, 'years')"
                v-model="taskDateForScheduling"
              />
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="Hora da Tarefa"
              vid="taskTime"
              rules="required"
            >
              <small class="text-danger">{{ errors[0] }}</small>
              <!--time -->
              <date-time-picker
                id="taskTime"
                format="HH:mm"
                formatted="HH:mm"
                time-only
                placeholder="Insira o Horário"
                v-model="taskTimeForScheduling"
              />
            </validation-provider>
          </validation-observer>
        </div>
      </b-collapse>

      <label> Deseja realizar alguma observação? </label>

      <b-form-textarea :class="collapseSchedule ? 'mb-5' : ''" v-model="confirmCompletion.detail" />
    </confirm>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";
import { BButton, BDropdown, BCollapse, BDropdownItem, BFormTextarea } from "bootstrap-vue";
import DateTimePicker from "@/views/forms/components/DateTimePicker.vue";
import moment from "moment";
import Confirm from "@/views/components/base/Confirm.vue";
import { openEmailChannel } from "@/services/callService";
import { openWhatsappChannel } from "@/services/callService";
import UserItem from "../UserItem.vue";
import UserList from "../UserList.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    Avatar,
    BButton,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    Confirm,
    DateTimePicker,
    UserItem,
    UserList,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },

  props: {
    opportunity: {
      type: Object,
      required: true,
      default: null,
    },

    fromChat: {
      type: Boolean,
      required: false,
      default: false,
    },

    fromOmnichannelDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },

    newestChat: {
      required: true,
      default: function () {
        return {};
      },
    },

    fromTaskForm: {
      type: Boolean,
      required: false,
      default: false,
    },

    task: {
      type: Object,
      required: false,
      default: null,
    },

    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      collapseSchedule: false,
      hasRequest: false,
      scheduleTask: this.task,
      taskDateForScheduling: moment().format("YYYY-MM-DD"),
      taskTimeForScheduling: moment().format("HH:mm"),
      taskType: this.task ? this.task.type : null,
      userForSchedulingError: false,

      isEditingOpName: false,

      confirmCompletion: {
        show: false,
        title: "",
        inner: "",
        detail: "",
      },
    };
  },

  computed: {
    seller() {
      return this.$store.getters["app/getUserData"];
    },

    currentWorkspace() {
      return this.$store.getters["app/getUserWorkspace"];
    },

    endedTypes() {
      return this.$store.getters["calendarStore/getEndedTypes"];
    },

    types() {
      return this.$store.getters["calendarStore/getActions"];
    },

    user() {
      return this.$store.getters["app/getUserData"];
    },

    role() {
      return this.$store.getters["app/getUserRole"];
    },

    isIOS() {
      const userAgent = navigator.userAgent;

      return /iPhone|iPad|iPod/i.test(userAgent);
    },

    isOpenRoom() {
      if (!this.newestChat) {
        return false;
      }

      return this.newestChat.closed_at === undefined || this.newestChat.closed_at === null;
    },
  },

  methods: {
    toggleEndedScheduled() {
      this.collapseSchedule = !this.collapseSchedule;
    },

    resetConfirmCompletion() {
      this.confirmCompletion = {
        show: false,
        title: "",
        inner: "",
      };
    },

    checkPermissionInRoom() {
      if (!this.newestChat) {
        return true;
      }

      if (this.newestChat.user_id != this.user.id && this.role == "salesRep") {
        return false;
      }

      return true;
    },

    getFirstName(userName) {
      return this.$service.toCapitalize(this.$service.splitFirstName(userName));
    },

    editOpportunityName() {
      if (!this.editable) return;

      this.isEditingOpName = !this.isEditingOpName;

      if (this.isEditingOpName) {
        this.$nextTick(() => {
          const input = document.getElementById("op-name-input");

          input?.focus();
        });

        return;
      }

      this.$emit("editOpportunityName", this.opportunity?.name);
    },

    openOpportunityFromTask() {
      if (this.fromTaskForm) {
        this.$store.dispatch("bmodalEvents/setCurrentOpportunityId", this.opportunity.id);

        this.$bvModal.show("bv-edit_task_opportunity");
      }
    },

    closeTask() {
      this.confirmCompletion.show = true;
      this.confirmCompletion.title = "Deseja concluir a tarefa?";
    },

    goToWhatsapp() {
      const informations = {
        seller_name: this.getFirstName(this.seller.name),
        opportunity: this.opportunity,
        workspace: this.currentWorkspace.name,
      };

      openWhatsappChannel(informations);
      this.$emit("whats");
    },

    triggerSingleVoipCall() {
      this.$emit("singleVoipCall");
    },

    openCall() {
      this.$emit("call");
    },

    openEmail(email) {
      openEmailChannel(email);
      this.$emit("email");
    },

    closeOpportunity() {
      this.$emit("closeByChat");
    },

    async copyNumber(number) {
      const textToCopy = number;

      try {
        await navigator.clipboard.writeText(textToCopy);
      } catch (err) {
        const tempInput = document.createElement("textarea");
        tempInput.value = textToCopy;
        document.body.appendChild(tempInput);

        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // Para dispositivos móveis

        try {
          document.execCommand("copy");
        } catch (err) {
          console.log(err);
        }

        document.body.removeChild(tempInput);
      }
    },

    getDefaultScheduleTaskValues() {
      this.scheduleTask.start_timestamp =
        this.taskDateForScheduling + " " + this.taskTimeForScheduling;

      this.scheduleTask.created_at =
        moment().format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss");

      this.scheduleTask.updated_at = this.scheduleTask.created_at;
      this.scheduleTask.duration = null;
      this.scheduleTask.id = null;
      this.scheduleTask.ended_type = null;
      this.scheduleTask.description = this.confirmCompletion.detail;
    },

    completeTask() {
      this.hasRequest = true;

      const { task } = this;

      let myTask = JSON.parse(JSON.stringify(task));

      myTask.status = "Completed";
      myTask.start_timestamp = moment(myTask.start_timestamp, "YYYY-MM-DD HH:mm");

      myTask.ended_at = moment().format("YYYY-MM-DD HH:mm:ss");
      myTask.ended_description = this.confirmCompletion.detail;

      myTask.ended_scheduled = myTask.ended_scheduled ? 1 : 0;
      myTask.type = this.taskType;

      myTask.user = this.user;
      myTask.user_id = this.user.id;

      delete myTask.updated_at;
      delete myTask.created_at;

      if (this.collapseSchedule) {
        this.completeTaskWithNewSchedule(myTask);
      } else {
        this.completeTaskWithouthNewSchedule(myTask);
      }
    },

    completeTaskWithouthNewSchedule(myTask) {
      this.$store
        .dispatch("calendarStore/endTask", myTask)
        .then(() => {
          myTask.opportunity.user_id = this.user.id;
          myTask.opportunity.user = this.user;

          this.$store
            .dispatch("opportunityStore/assign", myTask.opportunity)
            .then(() => {
              this.resetConfirmCompletion();
              this.hasRequest = false;
              this.$emit("finished");
            })
            .catch((error) => {
              this.hasSaveRequest = false;

              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    completeTaskWithNewSchedule(myTask) {
      if (!this.scheduleTask.user) {
        this.userForSchedulingError = true;
        return;
      }

      this.$store
        .dispatch("calendarStore/endTask", myTask)
        .then(() => {
          this.getDefaultScheduleTaskValues();

          let formRefs = {};

          formRefs = this.$refs.taskScheduleValidation;

          formRefs.validate().then((success) => {
            if (success) {
              this.hasRequest = true;

              this.$store
                .dispatch("calendarStore/save", this.scheduleTask)
                .then(() => {
                  myTask.opportunity.user_id = this.scheduleTask.user.id;
                  myTask.opportunity.user = this.scheduleTask.user;

                  this.$store
                    .dispatch("opportunityStore/assign", myTask.opportunity)
                    .then(() => {
                      this.resetConfirmCompletion();
                      this.hasRequest = false;
                      this.$emit("finished");
                    })
                    .catch((error) => {
                      this.hasSaveRequest = false;

                      console.log(error);
                    });
                })
                .catch((error) => {
                  this.$bvToast.toast("Algo deu errado, tente novamente!", {
                    title: `Erro ${error.response.status}`,
                    autoHideDelay: 3000,
                    variant: "danger",
                    toaster: "b-toaster-top-left",
                    solid: true,
                  });
                });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 7px 10px;
  gap: 5px;
  background: #ffffff;
  overflow: visible;

  .opportunity-name {
    font-size: 16px;
    align-items: center;
    color: #171717;
    cursor: pointer;

    .hovering-edit {
      display: none;
      color: #046af3;
    }
  }

  .op-name-input {
    font-size: 16px;
    width: 100%;
    padding: 4px 5px;
    margin-bottom: 4px;
    border-radius: 6px;
    border: solid 1px #d2d2d2;
    outline: none;
    color: #171717;
  }

  .first-row-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .second-row-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    margin-left: 5px;
  }

  .second-row-when-chat {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
    height: 100%;
    margin-left: 5px;
  }

  .third-row-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    .button-style {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 35px;
      padding: 0;
      border-radius: 8px;
      border: none;
    }

    #drop-actions {
      color: #ffffff;
      cursor: pointer;
    }

    :deep(#drop-actions) {
      #drop-actions__BV_toggle_ {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 35px;
        padding: 0;
        border-radius: 8px;
        border: none;
        background: #2ed47a !important;
      }

      .dropdown-toggle::after {
        display: none;
      }

      .dropdown-item {
        display: flex;
        align-items: center;
        gap: 10px;

        i {
          color: #046af3;
        }
      }
    }
  }
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  height: 30px;
  width: 30px;
  padding: 2px;
  border-radius: 100%;
  background: #046af3;
  color: #fff;
}

.ended-scheduled-check {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border: 1px solid #046af3;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #046af3;
  font-weight: 500;
  padding-left: 10px;
}

.ended-scheduled-check input {
  padding: 5px;
  border: 1px solid red;
}

.info-component:hover {
  .hovering-edit {
    display: inline;
  }
}
</style>

import { instance } from "../../config/index";

const initialState = () => ({
  selectedTabIdLists: "campaign",

  hasAnySelected: false,

  showToast: false,
  toastInfo: {},

  hasUpdateResquest: false,

  tablesLimit: 50,

  editedCampaigns: [],
  campaignFilters: [],
  campaignOffset: 0,

  editedMedias: [],
  mediaFilters: [],
  mediaOffset: 0,

  editedProducts: [],
  productFilters: [],
  productOffset: 0,

  editedSalesStages: [],
  salesStageFilters: [],
  salesStageOffset: 0,

  editedWorkspaces: [],
  workspaceFilters: [],
  workspaceOffset: 0,

  workspaceInactiveFilters: [],
  workspaceInactiveOffset: 0,

  userFilters: [],
  userOffset: 0,

  contactsFilters: [],
  contactsOffset: 0,

  terms: {
    salesRep: "Atendente",
  },
});

const getters = {
  getTerms(state) {
    return state.terms;
  },

  isUpdating(state) {
    return state.hasUpdateResquest;
  },

  showToast(state) {
    return state.showToast;
  },

  toastInfo(state) {
    return state.toastInfo;
  },

  getSelectedTabLists(state) {
    return state.selectedTabIdLists;
  },

  getLimit(state) {
    return state.tablesLimit;
  },

  getEditedCampaigns(state) {
    return state.editedCampaigns;
  },

  getCampaignFilters(state) {
    return state.campaignFilters;
  },

  getCampaignOffset(state) {
    return state.campaignOffset;
  },

  getEditedMedias(state) {
    return state.editedMedias;
  },

  getMediaFilters(state) {
    return state.mediaFilters;
  },

  getMediaOffset(state) {
    return state.mediaOffset;
  },

  getEditedProducts(state) {
    return state.editedProducts;
  },

  getProductFilters(state) {
    return state.productFilters;
  },

  getProductOffset(state) {
    return state.productOffset;
  },

  getEditedSalesStages(state) {
    return state.editedSalesStages;
  },

  getSalesStagesFilters(state) {
    return state.salesStageFilters;
  },

  getSalesStagesOffset(state) {
    return state.salesStageOffset;
  },

  getEditedWorkspaces(state) {
    return state.editedWorkspaces;
  },

  getWorkspaceFilters(state) {
    return state.workspaceFilters;
  },

  getWorkspaceOffset(state) {
    return state.workspaceOffset;
  },

  getWorkspaceInactiveFilters(state) {
    return state.workspaceInactiveFilters;
  },

  getWorkspaceInactiveOffset(state) {
    return state.workspaceInactiveOffset;
  },

  getUserFilters(state) {
    return state.userFilters;
  },

  getUserOffset(state) {
    return state.userOffset;
  },

  getContactsFilters(state) {
    return state.contactsFilters;
  },

  getContactsOffset(state) {
    return state.contactsOffset;
  },
};

const actions = {
  selectTabLists({ state }, tab) {
    state.selectedTabIdLists = tab;
  },

  setIsUpdating({ state }, boolean) {
    state.hasUpdateResquest = boolean;
  },

  setEditedCampaigns({ state }, editedList) {
    state.editedCampaigns = editedList;
  },

  setCampaignFilters({ state }, filters) {
    state.campaignFilters = filters;
  },

  setCampaignOffset({ state }, offset) {
    state.campaignOffset = offset;
  },

  setEditedMedias({ state }, editedList) {
    state.editedMedias = editedList;
  },

  setMediaFilters({ state }, filters) {
    state.mediaFilters = filters;
  },

  setMediaOffset({ state }, offset) {
    state.mediaOffset = offset;
  },

  setEditedProducts({ state }, editedList) {
    state.editedProducts = editedList;
  },

  setProductFilters({ state }, filters) {
    state.productFilters = filters;
  },

  setProductOffset({ state }, offset) {
    state.productOffset = offset;
  },

  setEditedSalesStages({ state }, editedList) {
    state.editedSalesStages = editedList;
  },

  setSalesStageFilters({ state }, filters) {
    state.salesStageFilters = filters;
  },

  setSalesStageOffset({ state }, offset) {
    state.salesStageOffset = offset;
  },

  setEditedWorkspaces({ state }, editedList) {
    state.editedWorkspaces = editedList;
  },

  setWorkspaceFilters({ state }, filters) {
    state.workspaceFilters = filters;
  },

  setWorkspaceOffset({ state }, offset) {
    state.workspaceOffset = offset;
  },

  setWorkspaceInactiveFilters({ state }, filters) {
    state.workspaceInactiveFilters = filters;
  },

  setWorkspaceInactiveOffset({ state }, offset) {
    state.workspaceInactiveOffset = offset;
  },

  setUserFilters({ state }, filters) {
    state.userFilters = filters;
  },

  setUserOffset({ state }, offset) {
    state.userOffset = offset;
  },

  setContactsFilters({ state }, filters) {
    state.contactsFilters = filters;
  },

  setContactsOffset({ state }, offset) {
    state.contactsOffset = offset;
  },

  //////////////////////////////////////

  clearToast({ state }) {
    state.showToast = false;
    state.toastInfo = {};
  },

  useToast({ state }, info) {
    state.toastInfo = info;
    state.showToast = true;

    setTimeout(() => {
      state.showToast = false;
      state.toastInfo = {};
    }, 3000);
  },

  //////////////////////////////////////

  clearEditionLists({ state }) {
    state.editedCampaigns = [];
    state.editedMedias = [];
    state.editedProducts = [];
    state.editedSalesStages = [];
    state.editedWorkspaces = [];
  },

  updateEditionList({ state, dispatch }, listInfo) {
    const allEditedLists = {
      campaign: (cell) => {
        const list = state.editedCampaigns.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedCampaigns", list);
      },

      media: (cell) => {
        const list = state.editedMedias.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedMedias", list);
      },

      product: (cell) => {
        const list = state.editedProducts.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedProducts", list);
      },

      stages: (cell) => {
        const list = state.editedSalesStages.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedSalesStages", list);
      },

      workspace: (cell) => {
        const list = state.editedWorkspaces.filter((item) => {
          return item.id !== cell.id;
        });

        list.push(cell);

        dispatch("setEditedWorkspaces", list);
      },
    };

    allEditedLists[listInfo.id](listInfo.cell);
  },

  saveAllEdits({ state, dispatch }, toastInfo) {
    dispatch("setIsUpdating", true);

    const campaignEditPromises = state.editedCampaigns.map((element) => {
      return dispatch("updateCampaign", element);
    });

    const mediaEditPromises = state.editedMedias.map((element) => {
      return dispatch("updateMedia", element);
    });

    const productEditPromises = state.editedProducts.map((element) => {
      return dispatch("updateProduct", element);
    });

    const saleStagesPromises = state.editedSalesStages.map((element) => {
      return dispatch("updateSaleStages", element);
    });

    const workspacePromises = state.editedWorkspaces.map((element) => {
      return dispatch("updateWorkspace", element);
    });

    const updatePromises = [
      ...campaignEditPromises,
      ...mediaEditPromises,
      ...productEditPromises,
      ...saleStagesPromises,
      ...workspacePromises,
    ];

    return Promise.all(updatePromises)
      .then((response) => {
        dispatch("useToast", toastInfo);

        return response;
      })
      .finally(() => {
        dispatch("clearEditionLists");
        dispatch("setIsUpdating", false);
      });
  },

  //////////////////////////////////////

  clearFilters({ state }) {
    state.campaignFilters = [];
    state.mediaFilters = [];
    state.productFilters = [];
    state.salesStageFilters = [];
    state.workspaceFilters = [];
    state.workspaceInactiveFilters = [];
    state.userFilters = [];
    state.contactsFilters = [];

    state.campaignOffset = 0;
    state.mediaOffset = 0;
    state.productOffset = 0;
    state.salesStageOffset = 0;
    state.workspaceOffset = 0;
    state.workspaceInactiveOffset = 0;
    state.userOffset = 0;
    state.contactsOffset = 0;
  },

  clearOffSet({ state }) {
    state.campaignFilters = state.campaignFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.mediaFilters = state.mediaFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.productFilters = state.productFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.salesStageFilters = state.salesStageFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.workspaceFilters = state.workspaceFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.workspaceInactiveFilters = state.workspaceInactiveFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.userFilters = state.userFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.contactsFilters = state.contactsFilters.filter((filter) => {
      return filter.query !== "offset";
    });

    state.campaignOffset = 0;
    state.mediaOffset = 0;
    state.productOffset = 0;
    state.salesStageOffset = 0;
    state.workspaceOffset = 0;
    state.workspaceInactiveOffset = 0;
    state.userOffset = 0;
    state.contactsOffset = 0;
  },

  //////////////////////////////////////

  fetchCampaigns({ state }, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/campaigns${params}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err.response));
    });
  },

  fetchMedias({ state }, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/medias${params}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => reject(err.response));
    });
  },

  fetchProducts({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/products${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchSalesStages({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/sales-stages${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchWorkspaces({}, params) {
    const postUrl = `/users/self/workspaces${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchUsers({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/users${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchContacts({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/contacts${params}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //////////////////////////////////////

  createCampaign({}, campaign) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/campaigns`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, campaign)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createMedia({}, media) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/medias`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, media)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createProduct({}, product) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/products`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, product)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createSaleStages({}, stage) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/sales-stages`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, stage)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  createWorkspace({}, workspace) {
    const postUrl = `/users/self/workspaces`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, workspace)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //////////////////////////////////////

  updateCampaign({}, campaign) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/campaigns/${campaign.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, campaign)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateMedia({}, media) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/medias/${media.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, media)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateProduct({}, product) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/products/${product.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, product)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateSaleStages({}, stage) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/sales-stages/${stage.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, stage)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateWorkspace({}, workspace) {
    const putUrl = `/users/self/workspaces/${workspace.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, workspace)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //////////////////////////////////////

  deleteCampaign({}, campaign) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/campaigns/${campaign.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteMedia({}, media) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/medias/${media.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteProduct({}, product) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/products/${product.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteSalesStages({}, stage) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/sales-stages/${stage.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteWorkspace({}, workspace) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/users/self/workspaces/${workspaceId}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  deleteUser({}, user) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const deleteUrl = `/workspaces/${workspaceId}/users/${user.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(deleteUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};

const mutations = {
  setHasAnySelected(state, boolean) {
    state.hasAnySelected = boolean;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};

<template>
  <div class="floating-messages">
    <div class="d-flex w-100" style="justify-content: flex-end; padding-right: 1rem">
      <i @click="closeFloatingNewRooms" class="icon-close-circle-outline" />
    </div>

    <div class="gray-detail" />

    <div v-if="!hasBotsRequest && imbotBots.length" class="box-title">Iniciar conversa</div>

    <div v-if="!hasBotsRequest && imbotBots.length" class="subtitle">
      Selecione um Bot integrado ao QRcode para iniciar a conversa.
    </div>

    <div v-if="!hasBotsRequest && !imbotBots.length" class="box-title">
      Nenhum Bot encontrado :(
    </div>

    <div v-if="!hasBotsRequest && !imbotBots.length" class="subtitle">
      Você não possui nenhum Bot integrado ao QRCode.
    </div>

    <div v-if="hasBotsRequest" class="box-with-no-bots">
      <lottie-animation
        class="lottie-animation"
        ref="anim"
        :animationData="require('@/assets/images/lottie/uploading-task-data.json')"
        :loop="true"
        :autoPlay="true"
        :speed="1"
      />
    </div>

    <div v-else-if="imbotBots.length" class="bots-box">
      <div v-for="(bot, index) in imbotBots" :key="index" @click="selectBot(bot)" class="bots">
        <div class="bot-icon">
          <i class="icon-imbot" />
        </div>

        <div class="bot-name">{{ bot.name }}</div>
      </div>
    </div>

    <div v-else class="box-with-no-bots">
      <img src="@/assets/images/icons/No-bots.svg" alt="no-bots" />
    </div>
  </div>
</template>

<script>
import { errorHandling } from "@/mixins";
import LottieAnimation from "lottie-web-vue";

export default {
  mixins: [errorHandling],

  components: {
    LottieAnimation,
  },

  props: {
    opportunity: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      hasBotsRequest: true,

      imbotBots: [],
      filteredQuickMessages: [],
    };
  },

  mounted() {
    this.getImbotBots();
  },

  methods: {
    emit(event, params) {
      this.$emit(event, params);
    },

    closeFloatingNewRooms() {
      this.emit("close");
    },

    getImbotBots() {
      this.hasBotsRequest = true;

      this.$store
        .dispatch("omnichannel/listBots")
        .then((response) => {
          this.imbotBots = response.data;

          if (this.imbotBots.length === 1) {
            this.selectBot(this.imbotBots[0]);
          }
        })
        .catch((error) => {
          this.MIXIN_showError(error?.response?.data, error?.response?.data?.status);
        })
        .finally(() => {
          this.hasBotsRequest = false;
        });
    },

    selectBot(bot) {
      if (bot.isDigitalEmployee) bot.keep_bot_on_dialog = true;

      this.emit("selectBot", bot);
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1000;
  bottom: calc(100% + 1px);
  padding-top: 1rem;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid #e5e5e5;
  box-shadow: -5px -5px 16px 0px #0000001a;
  background: #ffffff;

  .icon-close-circle-outline {
    font-size: 18px;
    color: #14223b;
    cursor: pointer;
  }

  .icon-close-circle-outline:hover {
    color: #ff0000 !important;
  }

  .gray-detail {
    height: 5px;
    width: 100%;
    max-width: 135px;
    margin-bottom: 20px;
    border-radius: 20px;
    opacity: 0.5;
    background: #c4ccd8;
  }

  .box-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    margin-bottom: 15px;
    color: #000000;
  }

  .subtitle {
    text-align: center;
    margin-bottom: 10px;
    color: #4c5862;
  }

  .box-with-no-bots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
    padding: 1rem;
    overflow: hidden;

    .lottie-animation {
      width: 100%;
      height: 100%;
    }

    img {
      width: 165px;
      height: auto;
    }
  }

  .bots-box {
    display: flex;
    flex-direction: column;
    max-height: 350px;
    width: 100%;
    overflow-y: scroll;

    .bots {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      gap: 5px;
      border-bottom: solid 1px #e5e5e5;
      cursor: pointer;

      .bot-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        border-radius: 50%;
        background: #02cdad;

        i {
          font-size: 20px;
          color: #ffffff;
        }
      }

      .bot-name {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #4c5862;
      }
    }

    .bots:last-child {
      border-bottom: none;
    }

    .bots:hover {
      background: #e5e5e5;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      border: 1px solid transparent;
      background-clip: content-box;
      box-shadow: inset 0 0 0 10px #c8c8c8;
    }
  }
}

@media (max-width: 800px) {
  .floating-messages {
    width: 100vw;
  }
}
</style>

const initialState = () => ({
  keep_filters: false,

  filters: {
    start_timestamp: null,
    end_timestamp: null,
    user: [],
    user_id: [],
    product_id: [],
    sort: "start_timestamp",
    source_type: null,
    campaign_id: null,
    media_id: null,
    product: [],
    closedType: null,
    onlyDelayed: false,
    sellerApprouch: null,
    type: null,
    ended_type: null,
    parent_id: null,
    opportunity: null,
  },

  total_ordering: [
    { id: "start_timestamp", title: "Por horário" },
    { id: "created_at", title: "Por criação" },
    { id: "updated_at", title: "Por atualização" },
  ],

  media_types: [
    { id: "Offline", title: "Offline" },
    { id: "Google", title: "Google" },
    { id: "SocialMedia", title: "Social Media" },
  ],

  redirectFromPipeline: null,
});

const getters = {
  getFilters(state) {
    return state.filters;
  },

  getTotalOrdering(state) {
    return state.total_ordering;
  },

  getMediaTypes(state) {
    return state.media_types;
  },

  getKeepFilters(state) {
    return state.keep_filters;
  },

  haveAnyActiveFilters(state) {
    if (state.filters.start_timestamp) {
      return true;
    }

    if (state.filters.end_timestamp) {
      return true;
    }

    if (state.filters.user_id.length > 0) {
      return true;
    }

    if (state.filters.product_id.length > 0) {
      return true;
    }

    if (state.filters.sort !== "start_timestamp") {
      return true;
    }

    if (state.filters.source_type) {
      return true;
    }

    if (state.filters.campaign_id) {
      return true;
    }

    if (state.filters.media_id) {
      return true;
    }

    if (state.filters.product.length > 0) {
      return true;
    }

    if (state.filters.closedType) {
      return true;
    }

    if (state.filters.onlyDelayed) {
      return true;
    }

    if (state.filters.sellerApprouch) {
      return true;
    }

    if (state.filters.type) {
      return true;
    }

    if (state.filters.ended_type) {
      return true;
    }

    if (state.filters.parent_id) {
      return true;
    }

    return false;
  },
};

const actions = {
  resetState({ state }) {
    const initial = initialState();

    const parentFromPipeline = state.redirectFromPipeline;

    if (parentFromPipeline) {
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });

      state.filters.parent_id = parentFromPipeline.id;
      state.filters.opportunity = parentFromPipeline;
      state.filters.start_timestamp = "2021-01-01";
    } else if (!state.keep_filters) {
      Object.keys(initial).forEach((key) => {
        state[key] = initial[key];
      });
    }

    state.redirectFromPipeline = null;
  },

  cleanValues({ state }) {
    if (!state.keep_filters) {
      state.filters = {
        start_timestamp: null,
        end_timestamp: null,
        user: [],
        user_id: [],
        product: [],
        sort: "-start_timestamp",
        source_type: null,
        campaign_id: null,
        media_id: null,
        product_id: [],
        closedType: null,
        onlyDelayed: false,
        sellerApprouch: null,
        type: null,
        ended_type: null,
        parent_id: null,
        opportunity: null,
      };

      return state.filters;
    }
  },

  setFilters({ state }, filters) {
    state.filters = filters;
  },

  setParentFromPipe({ state }, parentId) {
    state.redirectFromPipeline = parentId;
  },

  setKeepFilters({ state }, keepFilters) {
    state.keep_filters = keepFilters;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
};

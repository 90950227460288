import moment from "moment";

moment.locale("pt-BR");
moment.updateLocale("pt-br", {
  calendar: {
    lastDay: "[Ontem]",
    sameDay: "HH:mm",
    nextDay: "DD/MM/YY",
    lastWeek: "ddd",
    nextWeek: "DD/MM/YY",
    sameElse: "DD/MM/YY",
  },
});

export default {
  toUpperCase(str) {
    return str.toUpperCase();
  },

  formatBrlAmountSymbol(amount) {
    const price = amount ? amount : 0;

    return parseFloat(price).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  },

  formatBrlAmountNoSymbol(amount) {
    return parseFloat(amount).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
    });
  },

  getMonth(data, format) {
    return moment(data, format).format("M");
  },

  getStartDateMonth(month, format) {
    return moment(month, format).startOf("month").format("YYYY-MM-DD");
  },

  getEndDateMonth(month, format) {
    return moment(month, format).endOf("month").format("YYYY-MM-DD");
  },

  getDateDay(date, day) {
    return moment(date).add(day, "day").format("YYYY-MM-DD");
  },

  dateFormat(date, currentFormat = "YYYY-MM-DD", toFormat = "YYYY-MM-DD") {
    return moment(date, currentFormat).format(toFormat);
  },

  dateFormatFromTimestamp(date, toFormat = "YYYY-MM-DD") {
    return moment.unix(date).format(toFormat);
  },

  chatDateFormat(date) {
    return moment(date).subtract().calendar();
  },

  isEmptyObject(object) {
    return Object.values(object).length === 0;
  },

  isNullOrUndefined(value) {
    return value === null || value === undefined;
  },

  splitFirstName(completedName) {
    return completedName.split(" ")[0];
  },

  getFirstLetter(str) {
    if (!str) return "";

    return str.substr(0, 1);
  },

  isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  },

  isBlank(value) {
    return value === "";
  },

  toCapitalize(str) {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
  },

  formatQueryParam(queryParamsArr) {
    let formattedQuery = "?";

    queryParamsArr.forEach((item) => {
      if (item.param != null && item.param !== "") {
        formattedQuery += `${formattedQuery.slice(-1) !== "?" ? "&" : ""}${item.query}=${
          item.param
        }`;
      }
    });

    return formattedQuery;
  },

  randomKey() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  },

  colorHashFromString(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (let i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  },

  getStartTimestampForTheQuery(startTimestamp) {
    let selectedStartTimestamp;

    if (startTimestamp) {
      selectedStartTimestamp = `${moment(startTimestamp.replaceAll("-", "/")).format(
        "DD/MM/YYYY"
      )} 00:00:00`;

      selectedStartTimestamp = moment(selectedStartTimestamp, this.defaultDateFormat)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss")
        .toString();
    }

    return selectedStartTimestamp;
  },

  getEndTimestampForTheQuery(endTimestamp) {
    let selectedEndTimestamp;

    if (endTimestamp) {
      selectedEndTimestamp = `${moment(endTimestamp.replaceAll("-", "/")).format(
        "DD/MM/YYYY"
      )} 00:00:00`;

      selectedEndTimestamp = moment(selectedEndTimestamp, this.defaultDateFormat)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss")
        .toString();
    }

    return selectedEndTimestamp;
  },

  resetObjectFieldsToUndefined(obj) {
    if (!obj) return;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = undefined;
      }
    }
  },

  checkExportPermission(role) {
    const possibleRoles = {
      admin: true,
      manager: true,
      auxiliarTi: true,
      consultorFranquia: true,
      gestorFranquia: true,
      diretorFranquia: true,
      consultorComercial: true,
      diretorComercial: true,
      gerenteComercial: true,
      franqueado: true,
      master: true,
      ceo: true,
    };

    return possibleRoles[role] || false;
  },
};
